import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import { useSelector } from 'react-redux';
import { RenderFlag } from '../../helpers/language_helper';
import { setDefaultLanguageDispatch } from '../../store/actions';
import { useDispatch } from 'react-redux';


const LanguageDropdown = () => {
    const dispatch = useDispatch();
    const { LANGUAGE } = useSelector((state) => ({
        LANGUAGE: state.Language,
    }));
    const changeLanguageAction = lang => {
        dispatch(setDefaultLanguageDispatch(lang))
    };
    const [isLanguageDropdown, setIsLanguageDropdown] = useState(false);
    const toggleLanguageDropdown = () => {
        setIsLanguageDropdown(!isLanguageDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isLanguageDropdown} toggle={toggleLanguageDropdown} className="ms-1 topbar-head-dropdown header-item">
                <DropdownToggle className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" tag="button">
                    <RenderFlag flag={LANGUAGE.defaultLanguage.lang_flag} style={{ width: 30, height: 30 }} />
                    {/* {LANGUAGE.defaultLanguage.lang_name} */}
                </DropdownToggle>
                <DropdownMenu className="notify-item language py-2">
                    {(LANGUAGE.getLanguages).map((v, i) => (
                        <DropdownItem
                            key={i}
                            onClick={() => changeLanguageAction(v)}
                            className={`notify-item ${LANGUAGE.defaultLanguage.lang_id == v.lang_id ? "active" : "none"
                                }`}
                        >
                            <span className='me-2'>
                                <RenderFlag flag={v.lang_flag} style={{ width: 20, height: 20 }} />
                            </span>
                            <span className="align-middle">
                                {v.lang_name}
                            </span>
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default LanguageDropdown;