import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    Input,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { showToast, handleFormError } from '../../helpers/common_helper';
import { tableFetch } from '../../helpers/datatable_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { translate } from '../../helpers/language_helper';
import { Link } from 'react-router-dom';

const Receipts = () => {
    document.title = 'Receipts | Kinderiffic';
    const CSVLinkEl = useRef(null);

    // States-E-Module
    const { user, center, layoutModeType } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
        layoutModeType: state.Layout.layoutModeType,
    }));

    // States-E-Form
    const [form_processing, set_form_processing] = useState(false);
    const [parentID, setParentID] = useState(0);
    const [invoices, setInvoices] = useState([]);
    const [parentCode, setParentCode] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [balance, setBalance] = useState(0);
    const [payment, setPayment] = useState();
    const [desc, setDesc] = useState('');
    const [receiptID, setReceiptID] = useState(0);
    const [creditBack, setCreditBack] = useState(0);

    const [state, setState] = useState({
        data: [],
        CSVData: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        currentPage: 1,
        searchKeyword: '',
        sort: {
            field: 'receipt_id',
            dir: 'ASC',
        },
        errors: null,
        hideReceiptNumCol: false,
        hideNameCol: false,
        hideCodeCol: false,
        hidePhoneCol: false,
        hideEmailCol: false,
        hideAmountCol: false,
        hideDescCol: false,
        hideVatCol: false,
        hideDateCol: false,
        hidePictureCol: false,
        searching: false,
    });

    // Handle submit
    const handleSubmit = async () => {
        set_form_processing(true);

        let api_url = process.env.REACT_APP_API_URL + '/receipts/insert';

        if (isEditing) {
            api_url = process.env.REACT_APP_API_URL + '/receipts/update';
        }

        const fData = new FormData();
        fData.append('center_id', user.center_id);
        fData.append('center_timezone', center.center_timezone);
        fData.append('user_id', user.user_id);
        if (isEditing) {
            fData.append('receipt_id', receiptID);
        }
        fData.append('Receipts[parent_id]', parentID);
        fData.append('Receipts[receipt_amount]', payment);
        fData.append('Receipts[receipt_desc]', desc);
        try {
            const res = await axios.post(api_url, fData, {
                params: {
                    auth_key: user.auth_key,
                },
            });

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(state.currentPage);
                cancel();
            }

            if (!res.type) {
                if (res.data.length <= 0) {
                    showToast(res.message, 'danger');
                } else {
                    Object.keys(res.data).map((v, i) => {
                        res.data[v].map((vv, ii) => {
                            showToast(vv, 'danger');
                        })
                    })
                }
                setState({ ...state, errors: res.data });
                set_form_processing(false);
            }
        } catch (error) {
            showToast(error, 'danger');
            set_form_processing(false);
        }
    };

    // Cancel
    const cancel = () => {
        set_form_processing(false);
        setParentID(0);
        setBalance(0);
        setPayment('');
        setDesc('')
        setParentCode('');
        setReceiptID(0);
        setCreditBack(0);
        setIsEditing(false);
    };

    // Edit
    const editThis = (row) => {
        console.log('row', row);
        const parent_code = row.parent_code;

        setIsEditing(true);
        setReceiptID(row.receipt_id);
        setCreditBack(row.receipt_amount);
        setDesc(row.receipt_desc);
        setParentCode(parent_code);
        getParentBalance(undefined, parent_code);
    };

    // Get invoices
    const getParentBalance = (e, pcode = undefined) => {
        e !== undefined && e.preventDefault();

        if (pcode === undefined) {
            pcode = parentCode;
        }

        setState({ ...state, searching: true });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/receipts/get-parent-balance?auth_key=${user.auth_key}&center_id=${user.center_id}&search=${pcode}`
            )
            .then((res) => {
                console.log(res);
                if (res.type) {
                    setParentID(res.data.parent_id);
                    setBalance(res.data.balance);
                    setState({
                        ...state,
                        searching: false,
                    });
                } else {
                    showToast(res.message, 'warning');
                    setParentID(0);
                    setBalance(0);
                    setState({
                        ...state,
                        searching: false,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setParentID(0);
                setInvoices([]);
                setState({
                    ...state,
                    searching: false,
                });
            });
    };

    // Datatable: columns
    const columns = [
        {
            key: null,
            name: 'Actions',
            selector: (row) => (
                <React.Fragment>
                    <Button
                        title='Edit'
                        onClick={() => editThis(row)}
                        color='secondary'
                        className='btn-icon btn-sm me-1'>
                        <i className='ri-pencil-fill' />
                    </Button>
                    <Link
                        to={{
                            pathname: `/receipt-details`,
                            state: {
                                receipt_id: row.receipt_id,
                            },
                        }}
                        title='View'
                        className='btn btn-warning btn-sm'
                        style={{ fontSize: 11 }}>
                        <i className='ri-eye-line' />
                    </Link>
                </React.Fragment>
            ),
            sortable: false,
            width: '100px',
        },
        {
            key: 'receipt_num',
            name: translate('Receipt #'),
            width: '150px',
            selector: (row) => row.receipt_num,
            sortable: true,
            omit: state.hideReceiptNumCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideReceiptNumCol: !state.hideReceiptNumCol,
                }),
        },
        {
            key: 'parent_name',
            name: translate('Name'),
            width: '150px',
            selector: (row) => row.parent_name,
            sortable: true,
            omit: state.hideNameCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideNameCol: !state.hideNameCol,
                }),
        },
        {
            key: 'parent_code',
            name: translate('Code'),
            width: '150px',
            selector: (row) => row.parent_code,
            sortable: true,
            omit: state.hideCodeCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideCodeCol: !state.hideCodeCol,
                }),
        },
        {
            key: 'phone1',
            name: translate('Phone'),
            width: '150px',
            selector: (row) => row.phone1,
            sortable: true,
            omit: state.hidePhoneCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hidePhoneCol: !state.hidePhoneCol,
                }),
        },
        {
            key: 'email1',
            name: translate('Email'),
            width: '250px',
            selector: (row) => row.email1,
            sortable: true,
            omit: state.hideEmailCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideEmailCol: !state.hideEmailCol,
                }),
        },
        {
            key: 'receipt_amount',
            name: translate('Amount'),
            width: '150px',
            selector: (row) => row.receipt_amount,
            sortable: true,
            omit: state.hideAmountCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideAmountCol: !state.hideAmountCol,
                }),
        },
        {
            key: 'receipt_desc',
            name: translate('Description'),
            width: '150px',
            selector: (row) => row.receipt_desc,
            sortable: true,
            omit: state.hideDescCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideDescCol: !state.hideDescCol,
                }),
        },
        {
            key: 'created_on',
            name: translate('Date'),
            width: '150px',
            selector: (row) => moment(row.created_on).format('DD-MM-YYYY'),
            sortable: true,
            omit: state.hideDateCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideDateCol: !state.hideDateCol,
                }),
        },
    ];

    // Datatable: fetch data
    let fetchDataReq;
    const fetchData = async (page, newPerPage = 10) => {
        // setLoading(true);
        setState({ ...state, loading: true });
        if (fetchDataReq) {
            fetchDataReq.abort();
        }
        fetchDataReq = new AbortController();
        let parameters = {
            center_id: user.center_id,
            offset: (page - 1) * newPerPage,
            order: state.sort.field,
            dir: state.sort.dir,
            page: state.page,
            limit: newPerPage,
            search: state.searchKeyword,
            only_active: state.statusAll,
        };

        const res = await tableFetch(
            'receipts/fetch',
            fetchDataReq,
            parameters
        );
        if (res) {
            setState({
                ...state,
                data: res.data,
                currentPage: page,
                totalRows: res.total_count_filtered,
                perPage: newPerPage,
                loading: false,
            });
        } else {
            setState({ ...state, loading: false });
        }
        // setLoading(false);
    };

    // Datatable: sort
    const handleSort = (column, sortDirection) => {
        const field = column.key;
        const dir = sortDirection.toUpperCase();
        setState({ ...state, sort: { field, dir } });
    };

    // Datatable: page change
    const handlePageChange = (page) => {
        fetchData(page, state.perPage);
    };

    // Datatable: header component
    const subHeaderComponent = (
        <React.Fragment>
            <Button
                color='primary'
                className='me-3'
                onClick={() => CSVExport()}>
                CSV
            </Button>
            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light'
                    id='dropdownMenuButton'>
                    Columns Visibility <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {columns
                        .filter((col) => col.key !== null)
                        .map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => item.hideColFunc()}
                                className={item.omit ? 'text-muted' : ''}>
                                <FeatherIcon
                                    className='me-1'
                                    icon={item.omit ? 'eye-off' : 'eye'}
                                    size={14}
                                />{' '}
                                {item.name}
                            </DropdownItem>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon right'>
                <Input
                    value={state.searchKeyword}
                    type='text'
                    className='form-control form-control-icon'
                    id='iconleftInput'
                    placeholder='Search here...'
                    onChange={(e) =>
                        setState({ ...state, searchKeyword: e.target.value })
                    }
                />
                {state.searchKeyword != '' ? (
                    <i
                        onClick={() =>
                            setState({ ...state, searchKeyword: '' })
                        }
                        className='mdi mdi-close search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                ) : (
                    <i
                        className='mdi mdi-magnify search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                )}
            </div>
        </React.Fragment>
    );

    // Datatable: user effect
    useEffect(() => {
        fetchData(state.currentPage, state.perPage);
    }, [state.sort, state.searchKeyword]);

    // Datatable: export csv header
    const CSVHeader = () => {
        const filteredCol = columns
            .filter((column) => column.key !== null && !column.omit)
            .map((column) => {
                return { label: column.name, key: column.key };
            });

        return filteredCol;
    };

    // Datatable: export CSV
    const CSVExport = () => {
        const CSVHeaderKeys = CSVHeader().map((header) => header.key);

        const csvExportData = state.data.map((row) => {
            return Object.assign(
                {},
                ...Object.entries({ ...CSVHeaderKeys }).map(
                    ([item, index]) => ({
                        [index]: row[index],
                    })
                )
            );
        });

        // setCSVData(csvExportData);
        setState({ ...state, CSVData: csvExportData });

        setTimeout(() => {
            CSVLinkEl.current.link.click();
        });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <CSVLink
                headers={CSVHeader()}
                filename='services_export.csv'
                data={state.CSVData}
                ref={CSVLinkEl}
            />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    {translate('Receipts')}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className='g-3'>
                                <Col md={3}>
                                    <Form
                                        id='data-form'
                                        onSubmit={(e) => getParentBalance(e)}>
                                        <div className='input-group'>
                                            <input
                                                value={parentCode}
                                                type='text'
                                                id='iconleftInput'
                                                placeholder={translate(
                                                    'Search with parent code'
                                                )}
                                                onChange={(e) =>
                                                    setParentCode(
                                                        e.target.value
                                                    )
                                                }
                                                className='form-control'
                                            />
                                            {!isEditing && <button
                                                type='submit'
                                                className='btn btn-danger'>
                                                SEARCH
                                            </button>}
                                        </div>
                                    </Form>
                                </Col>
                                <Col
                                    className='d-flex flex-column justify-content-center align-items-center'>
                                    {state.searching && (
                                        <Spinner
                                            style={{ marginright: 15 }}
                                            size='sm'
                                            color='danger'
                                            className='flex-shrink-0'></Spinner>
                                    )}
                                    {balance != '0' && (
                                        <React.Fragment>
                                            <strong>
                                                {translate('Balance')}
                                            </strong>
                                            &nbsp;
                                            {balance}
                                            {isEditing &&
                                                ' + ' +
                                                parseFloat(
                                                    creditBack
                                                )}
                                        </React.Fragment>
                                    )}
                                </Col>
                                <Col md={2}>
                                    <Input
                                        type='number'
                                        value={payment}
                                        onChange={(e) =>
                                            setPayment(e.target.value)
                                        }
                                        placeholder={translate('Payment')}
                                        invalid={
                                            handleFormError(
                                                'receipt_amount',
                                                state.errors
                                            ).show
                                        }
                                    />
                                </Col>
                                <Col md={3}>
                                    <Input
                                        type='text'
                                        value={desc}
                                        onChange={(e) =>
                                            setDesc(e.target.value)
                                        }
                                        placeholder={translate('Description')}
                                        invalid={
                                            handleFormError(
                                                'receipt_desc',
                                                state.errors
                                            ).show
                                        }
                                    />
                                </Col>
                                <Col className='d-flex flex-row justify-content-center align-items-center'>
                                    <Button
                                        onClick={() => handleSubmit()}
                                        disabled={balance === 0 && true}
                                        color={isEditing ? 'warning' : 'success'}
                                        size="sm"
                                    >
                                        {form_processing
                                            ? <Spinner
                                                style={{ marginRight: 15 }}
                                                size='sm'
                                                color='danger'
                                                className='flex-shrink-0'></Spinner>
                                            : translate('COLLECT NOW')}
                                    </Button>
                                    <Button
                                        onClick={() => cancel()}
                                        color='primary'
                                        size="sm"
                                        disabled={parentID === 0 && true}
                                        className='ms-3'>
                                        {translate('CANCEL')}
                                    </Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={state.data}
                                progressPending={state.loading}
                                pagination
                                paginationServer
                                paginationTotalRows={state.totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={(newPerPage, page) =>
                                    fetchData(page, newPerPage)
                                }
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={handleSort}
                                sortServer
                                theme={
                                    layoutModeType == 'dark'
                                        ? 'dark'
                                        : 'default'
                                }
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Receipts;
