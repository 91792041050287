import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from './actionTypes';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';

//Include Both Helper File with needed methods
import { getFirebaseBackend } from '../../../helpers/firebase_helper';
import {
    postFakeLogin,
    postJwtLogin,
    postSocialLogin,
} from '../../../helpers/fakebackend_helper';

import { saveCenter, removeCenter } from '../center/actions';

import axios from 'axios';
import { removeUser, saveUser } from '../user/actions';

const fireBaseBackend = getFirebaseBackend();

const loginRequest = (authData) => {
    console.log('authData', authData);
    // return axios.get(process.env.REACT_APP_API_URL + '/login', {
    //     'withCredentials': true,
    //     'auth': authData
    // }).then((response) => response);
    let fData = new FormData()
    fData.append('username', authData.username)
    fData.append('password', authData.password)
    return axios
        .post(
            process.env.REACT_APP_API_URL + '/login',
            fData,
            // {
            //     withCredentials: true,
            //     auth: authData,
            // }
        )
        .then((response) => response);
};

function* loginUser({ payload: { user, history } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(
                fireBaseBackend.loginUser,
                user.email,
                user.password
            );
            yield put(loginSuccess(response));
        } else if (process.env.REACT_APP_DEFAULTAUTH === 'jwt') {
            const response = yield call(postJwtLogin, {
                email: user.email,
                password: user.password,
            });
            sessionStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        } else if (process.env.REACT_APP_API_URL) {
            const response = yield call(loginRequest, {
                username: user.username,
                password: user.password,
            });
            console.log(response);
            if (response.type) {
                sessionStorage.setItem(
                    'authUser',
                    JSON.stringify(response.data.loginData)
                );
                yield put(loginSuccess());
                yield put(saveUser({ ...response.data.loginData, profile: response.data.userData }));
                yield put(saveCenter(response.data.centerData));
                history.push('/dashboard');
            } else {
                yield put(apiError(response.message));
            }
        }
    } catch (error) {
        yield put(apiError(error));
    }
}

function* logoutUser() {
    try {
        sessionStorage.removeItem('authUser');
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const response = yield call(fireBaseBackend.logout);
            yield put(logoutUserSuccess(LOGOUT_USER, response));
        } else {
            yield put(logoutUserSuccess(LOGOUT_USER, true));
            yield put(removeUser());
            yield put(removeCenter());
        }
    } catch (error) {
        yield put(apiError(LOGOUT_USER, error));
    }
}

function* socialLogin({ payload: { data, history, type } }) {
    try {
        if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
            const fireBaseBackend = getFirebaseBackend();
            const response = yield call(
                fireBaseBackend.socialLoginUser,
                data,
                type
            );
            sessionStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        } else {
            const response = yield call(postSocialLogin, data);
            sessionStorage.setItem('authUser', JSON.stringify(response));
            yield put(loginSuccess(response));
        }
        history.push('/dashboard');
    } catch (error) {
        yield put(apiError(error));
    }
}

function* authSaga() {
    yield takeEvery(LOGIN_USER, loginUser);
    yield takeLatest(SOCIAL_LOGIN, socialLogin);
    yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
