import axios from "axios";
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { FetchLabelLanguagesDispatch, FetchLanguagesDispatch, setDefaultLanguage } from "./actions";

const fetchLanguageAxios = () => {
    return axios.get(process.env.REACT_APP_API_URL + '/language/get-languages')
        .then((response) => response)
        .catch((error) => error)
}


function* FetchLanguages() {
    const response = yield call(fetchLanguageAxios)
    console.log(' yield call(fetchLanguageAxios)', response);
    if (response.type) {
        yield put(FetchLanguagesDispatch(response.data))
    }
}


function* FetchLanguageLabels({ payload: { model_name, filetype, offset, data } }) {
    if (offset == undefined) {
        offset = 0
        data = []
    }
    const response = yield call(FetchLabelLanguagesAxios, model_name, filetype, offset)
    yield put(FetchLabelLanguagesDispatch(response))
}

const FetchLabelLanguagesAxios = (model_name, filetype, offset, data = []) => {
    return axios.get(process.env.REACT_APP_API_URL + '/language/get-language-labels', {
        params: {
            offset: offset,
            filetype: filetype,
            model_name: model_name,
        },
    })
        .then((response) => {
            if (response.type) {
                const resData = response.data
                if (resData.length > 0) {
                    resData.map((v, i) => {
                        data.push(v)
                    })
                    return FetchLabelLanguagesAxios(model_name, filetype, (offset + 100), data)
                } else {
                    return data
                }
            }
        })
        .catch((error) => error)
}

// function* setDefaultLanguageDispatch({ type, payload }) {
//     console.log('SAGA setDefaultLanguageDispatch', type, payload);
//     yield put(setDefaultLanguage(payload))
// }


function* LanguageSaga() {
    yield takeLatest('LOADING_LANGUAGES_DATA', FetchLanguages);
    yield takeLatest('LOADING_LABELS_DATA', FetchLanguageLabels);
    // yield takeLatest('DEFAULT_LANGUAGE', setDefaultLanguageDispatch);
}
export default LanguageSaga;

