import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormFeedback,
    Label,
    Input,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import {
    handleFormError,
    showToast,
    getRoles,
    getCountries,
    Thumbnail,
    getDDLOptions,
} from '../../helpers/common_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';

// Import React FilePond
import { FilePond, registerPlugin } from 'react-filepond';
// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const Employees = () => {
    document.title = 'Employees | Kinderiffic';
    const offCanvasWidth = '50%';
    const CSVLinkEl = useRef(null);

    // States-E-Module
    const { user, center, layoutModeType } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
        layoutModeType: state.Layout.layoutModeType,
    }));
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);
    const genderOptions = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' },
    ];
    const [rolesOptions, setRolesOptions] = useState([]);
    const [countriesOptions, setCountriesOptions] = useState([]);
    useEffect(() => {
        async function componentDidMount() {
            setRolesOptions(
                getDDLOptions(await getRoles(), 'role_id', 'role_name')
            );
            setCountriesOptions(
                getDDLOptions(await getCountries(), 'country_id', 'name_en')
            );
        }
        componentDidMount();
    }, []);
    // States-E-Form
    const [form_processing, set_form_processing] = useState(false);
    const [employee_id, set_employee_id] = useState(0);
    const [employee_name, set_employee_name] = useState('');
    const [employee_note, set_employee_note] = useState('');
    const [employee_dob, set_employee_dob] = useState('');
    const [employee_phone1, set_employee_phone1] = useState('');
    const [employee_email1, set_employee_email1] = useState('');
    const [tax_exempt, set_tax_exempt] = useState(0);
    const [employee_gender, set_employee_gender] = useState(null);
    const [employee_status, set_employee_status] = useState(1);
    const [emergency_contact_number, set_emergency_contact_number] =
        useState('');
    const [emergency_contact_name, set_emergency_contact_name] = useState('');
    const [user_name, set_user_name] = useState('');
    const [password, set_password] = useState('');
    const [user_role, set_user_role] = useState(null);
    const [country, set_country] = useState(null);
    const [files, set_files] = useState([]);

    // States-E-Datatable
    const [data, setData] = useState([]);
    const [CSVData, setCSVData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [sort, setSort] = useState({
        field: 'employee_name',
        dir: 'ASC',
    });
    const [statusAll, setStatusAll] = useState(true);
    const [errors, setErrors] = useState(null);

    // States-E-ColumnsVisibility
    const [hideNameCol, setHideNameCol] = useState(false);
    const [hideDOBCol, setHideDOBCol] = useState(false);
    const [hideGenderCol, setHideGenderCol] = useState(false);
    const [hidePhone1Col, setHidePhone1Col] = useState(false);
    const [hidePhone2Col, setHidePhone2Col] = useState(false);
    const [hideEmail1Col, setHideEmail1Col] = useState(false);
    const [hideEmail2Col, setHideEmail2Col] = useState(false);
    const [hideStatusCol, setHideStatusCol] = useState(false);
    const [hideAddressCol, setHideAddressCol] = useState(false);
    const [hideEmergencyContactNoCol, setHideEmergencyContactNoCol] =
        useState(false);
    const [hideEmergencyContactNameCol, setHideEmergencyContactNameCol] =
        useState(false);
    const [hideQualificationCol, setHideQualificationCol] = useState(false);
    const [hidePictureCol, setHidePictureCol] = useState(false);
    const [hideCountryCol, setHideCountryCol] = useState(false);
    const [hideUsernameCol, setHideUsernameCol] = useState(false);
    const [hidePasswordCol, setHidePasswordCol] = useState(false);
    const [hideRoleCol, setHideRoleCol] = useState(false);
    const [hideJoiningDateCol, setHideJoiningDateCol] = useState(false);
    const [hideLastDayCol, setHideLastDayCol] = useState(false);
    const [hideNoteCol, setHideNoteCol] = useState(false);

    // Toggle off canvas
    const toggleOffCanvas = () => {
        setOffCanvasOpen(!offCanvasOpen);
    };

    // Handle selects
    const handleGender = (type) => {
        set_employee_gender(type);
    };
    const handleRole = (type) => {
        set_user_role(type);
    };
    const handleCountry = (type) => {
        set_country(type);
    };
    const genderFldStyle = {
        control: () => ({
            borderColor: handleFormError('employee_gender', errors).show
                ? '#f06548'
                : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };
    const roleFldStyle = {
        control: () => ({
            borderColor: handleFormError('role_id', errors).show
                ? '#f06548'
                : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };
    const countryFldStyle = {
        control: () => ({
            borderColor: handleFormError('country_id', errors).show
                ? '#f06548'
                : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        set_form_processing(true);

        const dataForm = document.getElementById('data-form');
        const fData = new FormData(dataForm);
        fData.append('center_id', user.center_id);
        fData.append('center_timezone', center.center_timezone);
        fData.append('user_id', user.user_id);
        fData.append('employee_id', employee_id);
        fData.append('Employees[center_id]', user.center_id);
        fData.set('Employees[employee_status]', employee_status);
        console.log('files', files);
        if (files !== undefined && files.length > 0) {
            if (typeof files[0].source !== 'string') {
                fData.set('employee_picture', files[0].file);
            }
        }
        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL +
                '/employees/' +
                (employee_id ? 'update' : 'insert'),
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                resetForm();
                fetchData(currentPage);
            }

            if (!res.type) {
                set_form_processing(false);
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            set_form_processing(false);
            showToast(error, 'danger');
        }
    };

    // Reset form
    const resetForm = () => {
        set_employee_id(0);
        set_employee_name('');
        set_employee_note('');
        set_employee_dob('');
        set_employee_phone1('');
        set_employee_email1('');
        set_emergency_contact_name('');
        set_emergency_contact_number('');
        set_user_name('');
        set_password('');
        set_files();
        set_employee_status(1);
        set_employee_gender(null);
        set_user_role(null);
        set_country(null);
        setErrors(null);
        set_form_processing(false);
        toggleOffCanvas();
    };

    // Edit
    const editThis = (row) => {
        toggleOffCanvas();

        const formFields = [
            { employee_id: set_employee_id },
            { employee_name: set_employee_name },
            { employee_dob: set_employee_dob },
            { employee_phone1: set_employee_phone1 },
            { employee_email1: set_employee_email1 },
            { emergency_contact_number: set_emergency_contact_number },
            { emergency_contact_name: set_emergency_contact_name },
            { employee_gender: set_employee_gender },
            { user_role: set_user_role },
            { country: set_country },
            { user_name: set_user_name },
            { password: set_password },
            { employee_note: set_employee_note },
            { files: set_files },
            { employee_status: set_employee_status },
        ];

        formFields.map((item, index) => {
            const key = Object.keys(item)[0];
            if ('employee_gender' === key) {
                item[key]({
                    value: row[key],
                    label: row[key] == 'M' ? 'Male' : 'Female',
                });
            } else if ('user_role' === key) {
                item[key]({
                    value: row['role_id'],
                    label: row['role_name'],
                });
            } else if ('country' === key) {
                item[key]({
                    value: row['country_id'],
                    label: row['country_name'],
                });
            } else if ('employee_status' === key) {
                item[key](parseInt(row[key]));
            } else if ('employee_dob' === key) {
                item[key](moment(row[key]).format('DD-MM-YYYY'));
            } else if ('files' === key && row['employee_picture'] !== null) {
                const path =
                    process.env.REACT_APP_API_URL +
                    '/web/attachments/centers/' +
                    user.center_id +
                    '/employees/' +
                    row['employee_picture'];

                item[key](path);
            } else {
                item[key](row[key]);
            }
        });
    };

    // Delete
    const deleteThis = async (id) => {
        try {
            const fData = new FormData();
            fData.append('employee_id', id);
            fData.append('center_id', user.center_id);

            const res = await axios.post(
                process.env.REACT_APP_API_URL + '/employees/delete',
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(currentPage);
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            showToast(error, 'danger');
        }
    };

    // Datatable: columns
    const columns = [
        {
            key: null,
            name: 'Actions',
            selector: (row) => (
                <React.Fragment>
                    <Button
                        title='Edit'
                        onClick={() => editThis(row)}
                        color='secondary'
                        className='btn-icon btn-sm me-1'>
                        <i className='ri-pencil-fill' />
                    </Button>
                    {/* <Button
                        title='Delete'
                        onClick={() => deleteThis(row.employee_id)}
                        color='danger'
                        className='btn-icon btn-sm'>
                        <i className='ri-delete-bin-5-line' />
                    </Button> */}
                </React.Fragment>
            ),
            sortable: false,
            width: '100px',
        },
        {
            key: 'employee_status',
            name: 'Status',
            width: '90px',
            selector: (row) => (
                <div className='row-checkbox'>
                    <div
                        className='form-check form-switch form-switch-success form-switch-md'
                        dir='ltr'>
                        <Input
                            value={row.employee_status}
                            type='checkbox'
                            className='form-check-input'
                            defaultChecked={parseInt(row.employee_status)}
                            onChange={() => handleToggleStatus(row.employee_id)}
                        />
                    </div>
                </div>
            ),
            sortable: true,
            omit: hideStatusCol,
            hideColFunc: () => setHideStatusCol(!hideStatusCol),
        },
        {
            key: 'employee_picture',
            name: 'Picture',
            selector: (row) => (
                <Thumbnail
                    moduleName='employees'
                    fileName={row.employee_picture}
                />
            ),
            sortable: true,
            omit: hidePictureCol,
            hideColFunc: () => setHidePictureCol(!hidePictureCol),
        },
        {
            key: 'employee_name',
            name: 'Name',
            selector: (row) => row.employee_name,
            sortable: true,
            omit: hideNameCol,
            hideColFunc: () => setHideNameCol(!hideNameCol),
        },
        {
            key: 'employee_dob',
            name: 'DOB',
            selector: (row) => moment(row.employee_dob).format('DD-MM-YYYY'),
            sortable: true,
            omit: hideDOBCol,
            hideColFunc: () => setHideDOBCol(!hideDOBCol),
        },
        {
            key: 'employee_gender',
            name: 'Gender',
            selector: (row) => (row.employee_gender == 'M' ? 'Male' : 'Female'),
            sortable: true,
            omit: hideGenderCol,
            hideColFunc: () => setHideGenderCol(!hideGenderCol),
        },
        {
            key: 'employee_phone1',
            name: 'Phone',
            selector: (row) => row.employee_phone1,
            sortable: true,
            omit: hidePhone1Col,
            hideColFunc: () => setHidePhone1Col(!hidePhone1Col),
        },
        {
            key: 'employee_email1',
            name: 'Email',
            selector: (row) => row.employee_email1,
            sortable: true,
            omit: hideEmail1Col,
            hideColFunc: () => setHideEmail1Col(!hideEmail1Col),
        },
        {
            key: 'emergency_contact_number',
            name: 'Emergency Contact No.',
            selector: (row) => row.emergency_contact_number,
            sortable: true,
            omit: hideEmergencyContactNoCol,
            hideColFunc: () =>
                setHideEmergencyContactNoCol(!hideEmergencyContactNoCol),
        },
        {
            key: 'emergency_contact_name',
            name: 'Emergency Contact Name',
            selector: (row) => row.emergency_contact_name,
            sortable: true,
            omit: hideEmergencyContactNameCol,
            hideColFunc: () =>
                setHideEmergencyContactNameCol(!hideEmergencyContactNameCol),
        },
        {
            key: 'country_name',
            name: 'Country',
            selector: (row) => row.country_name,
            sortable: true,
            omit: hideCountryCol,
            hideColFunc: () => setHideCountryCol(!hideCountryCol),
        },
        {
            key: 'user_name',
            name: 'Username',
            selector: (row) => row.user_name,
            sortable: true,
            omit: hideUsernameCol,
            hideColFunc: () => setHideUsernameCol(!hideUsernameCol),
        },
        {
            key: 'password',
            name: 'Password',
            selector: (row) => row.password,
            sortable: true,
            omit: hidePasswordCol,
            hideColFunc: () => setHidePasswordCol(!hidePasswordCol),
        },
        {
            key: 'role_name',
            name: 'Role',
            selector: (row) => row.role_name,
            sortable: true,
            omit: hideRoleCol,
            hideColFunc: () => setHideRoleCol(!hideRoleCol),
        },
        {
            key: 'employee_note',
            name: 'Note',
            selector: (row) => row.employee_note,
            sortable: true,
            omit: hideNoteCol,
            hideColFunc: () => setHideNoteCol(!hideNoteCol),
        },
    ];

    // Datatable: fetch data
    const fetchData = async (page) => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/employees/fetch?auth_key=${user.auth_key
            }&center_id=${user.center_id}&offset=${(page - 1) * perPage
            }&order=${sort.field}&dir=${sort.dir
            }&page=${page}&limit=${perPage}&search=${searchKeyword}&only_active=${statusAll}`
        );

        setData(response.data);
        setCurrentPage(page);
        setTotalRows(response.total_count_filtered);
        setLoading(false);
    };

    // Datatable: sort
    const handleSort = (column, sortDirection) => {
        const field = column.key;
        const dir = sortDirection.toUpperCase();

        setSort({ field, dir });
    };

    // Datatable: page change
    const handlePageChange = (page) => {
        fetchData(page);
    };

    // Datatable: per page rows change
    const handlePerRowsChange = async (newPerPage, page) => {
        setLoading(true);

        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/employees/fetch?auth_key=${user.auth_key
            }&center_id=${user.center_id}&offset=${(page - 1) * perPage
            }&order=${sort.field}&dir=${sort.dir
            }&page=${page}&limit=${newPerPage}&search=${searchKeyword}&only_active=${statusAll}`
        );

        setData(response.data);
        setPerPage(newPerPage);
        setLoading(false);
    };

    // Datatable: header component
    const subHeaderComponent = (
        <React.Fragment>
            <div
                className='form-check form-switch form-switch-success form-switch-md me-auto'
                style={{ marginLeft: '112px' }}>
                <Input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='SwitchCheck3'
                    defaultChecked={statusAll}
                    onChange={() => setStatusAll(!statusAll)}
                />
                <Label className='form-check-label' for='SwitchCheck3'>
                    {statusAll ? 'Active' : 'All'}
                </Label>
            </div>
            <Button
                color='primary'
                className='me-3'
                onClick={() => CSVExport()}>
                CSV
            </Button>
            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light'
                    id='dropdownMenuButton'>
                    Columns Visibility <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {columns
                        .filter((col) => col.key !== null)
                        .map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => item.hideColFunc()}
                                className={item.omit ? 'text-muted' : ''}>
                                <FeatherIcon
                                    className='me-1'
                                    icon={item.omit ? 'eye-off' : 'eye'}
                                    size={14}
                                />{' '}
                                {item.name}
                            </DropdownItem>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon left'>
                <Input
                    value={searchKeyword}
                    type='text'
                    className='form-control form-control-icon'
                    id='iconleftInput'
                    placeholder='Search here...'
                    onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <i
                    className='mdi mdi-magnify search-widget-icon'
                    style={{ fontSize: '15px' }}></i>
            </div>
            <Button
                color='danger'
                className='btn-icon ms-2'
                title='Clear'
                onClick={() => setSearchKeyword('')}>
                <FeatherIcon icon='x' size={15} />
            </Button>
        </React.Fragment>
    );

    // Datatable: user effect
    useEffect(() => {
        fetchData(currentPage);
    }, [sort, searchKeyword, statusAll]);

    // Datatable: export csv header
    const CSVHeader = () => {
        const filteredCol = columns
            .filter((column) => column.key !== null && !column.omit)
            .map((column) => {
                return { label: column.name, key: column.key };
            });

        return filteredCol;
    };

    // Datatable: export CSV
    const CSVExport = () => {
        const CSVHeaderKeys = CSVHeader().map((header) => header.key);

        const csvExportData = data.map((row) => {
            return Object.assign(
                {},
                ...Object.entries({ ...CSVHeaderKeys }).map(
                    ([item, index]) => ({
                        [index]: row[index],
                    })
                )
            );
        });

        setCSVData(csvExportData);

        setTimeout(() => {
            CSVLinkEl.current.link.click();
        });
    };

    // Datatable: toggle status
    const handleToggleStatus = async (id) => {
        const fData = new FormData();
        fData.append('center_id', user.center_id);
        fData.append('employee_id', id);

        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL + '/employees/toggle-status',
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(currentPage);
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setErrors(res.data);
            }
        } catch (error) {
            showToast(error, 'danger');
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <CSVLink
                headers={CSVHeader()}
                filename='services_export.csv'
                data={CSVData}
                ref={CSVLinkEl}
            />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>Employees</h4>
                                <Button
                                    onClick={() => toggleOffCanvas()}
                                    color='success'>
                                    <span>Add Employee</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={handlePerRowsChange}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={handleSort}
                                sortServer
                                theme={
                                    layoutModeType == 'dark'
                                        ? 'dark'
                                        : 'default'
                                }
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={offCanvasOpen}
                    toggle={resetForm}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={resetForm}>
                        <span className='m-0 me-2'>
                            {employee_id ? 'Update' : 'Add'} Employee
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            id='data-form'
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='employee_name'
                                                className='form-label'>
                                                Name
                                            </Label>
                                            <Input
                                                name='Employees[employee_name]'
                                                value={employee_name}
                                                onChange={(e) =>
                                                    set_employee_name(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='employee_name'
                                                invalid={
                                                    handleFormError(
                                                        'employee_name',
                                                        errors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_name',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='employee_dob'
                                                className='form-label'>
                                                DOB
                                            </Label>
                                            <Flatpickr
                                                name='Employees[employee_dob]'
                                                value={employee_dob}
                                                className={
                                                    handleFormError(
                                                        'employee_dob',
                                                        errors
                                                    ).show
                                                        ? 'form-control is-invalid'
                                                        : 'form-control'
                                                }
                                                options={{
                                                    dateFormat: 'd-m-Y',
                                                }}
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_dob',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='employee_phone1'
                                                className='form-label'>
                                                Phone
                                            </Label>
                                            <Input
                                                name='Employees[employee_phone1]'
                                                value={employee_phone1}
                                                onChange={(e) =>
                                                    set_employee_phone1(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                id='employee_phone1'
                                                invalid={
                                                    handleFormError(
                                                        'employee_phone1',
                                                        errors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_phon1',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='employee_email1'
                                                className='form-label'>
                                                Email
                                            </Label>
                                            <Input
                                                name='Employees[employee_email1]'
                                                value={employee_email1}
                                                onChange={(e) =>
                                                    set_employee_email1(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                id='employee_email1'
                                                invalid={
                                                    handleFormError(
                                                        'employee_email1',
                                                        errors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_email1',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='emergency_contact_number'
                                                className='form-label'>
                                                Emergency Contact Number
                                            </Label>
                                            <Input
                                                name='Employees[emergency_contact_number]'
                                                value={emergency_contact_number}
                                                onChange={(e) =>
                                                    set_emergency_contact_number(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                id='emergency_contact_number'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='emergency_contact_name'
                                                className='form-label'>
                                                Emergency Contact Name
                                            </Label>
                                            <Input
                                                name='Employees[emergency_contact_name]'
                                                value={emergency_contact_name}
                                                onChange={(e) =>
                                                    set_emergency_contact_name(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                id='emergency_contact_name'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='user_name'
                                                className='form-label'>
                                                Username
                                            </Label>
                                            <Input
                                                name='Employees[user_name]'
                                                value={user_name}
                                                onChange={(e) =>
                                                    set_user_name(
                                                        e.target.value
                                                    )
                                                }
                                                type='text'
                                                className='form-control'
                                                id='user_name'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='password'
                                                className='form-label'>
                                                Password
                                            </Label>
                                            <Input
                                                name='Employees[password]'
                                                value={password}
                                                onChange={(e) =>
                                                    set_password(e.target.value)
                                                }
                                                type='text'
                                                className='form-control'
                                                id='password'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mb-3'>
                                            <Label className='employee_gender'>
                                                Gender
                                            </Label>
                                            <Select
                                                className={
                                                    handleFormError(
                                                        'employee_gender',
                                                        errors
                                                    ).show && 'is-invalid'
                                                }
                                                styles={genderFldStyle}
                                                name='Employees[employee_gender]'
                                                value={employee_gender}
                                                onChange={() => {
                                                    handleGender();
                                                }}
                                                options={genderOptions}
                                                invalid={true}
                                                id='employee_gender'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_gender',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mb-3'>
                                            <Label className='role_id'>
                                                Role
                                            </Label>
                                            <Select
                                                className={
                                                    handleFormError(
                                                        'role_id',
                                                        errors
                                                    ).show && 'is-invalid'
                                                }
                                                styles={roleFldStyle}
                                                name='Employees[role_id]'
                                                value={user_role}
                                                onChange={() => {
                                                    handleRole();
                                                }}
                                                options={rolesOptions}
                                                invalid={true}
                                                id='role_id'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'role_id',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mb-3'>
                                            <Label className='country_id'>
                                                Country
                                            </Label>
                                            <Select
                                                className={
                                                    handleFormError(
                                                        'country_id',
                                                        errors
                                                    ).show && 'is-invalid'
                                                }
                                                styles={countryFldStyle}
                                                name='Employees[country_id]'
                                                value={country}
                                                onChange={() => handleCountry()}
                                                options={countriesOptions}
                                                invalid={true}
                                                id='country_id'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'country_id',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='employee_picture'>
                                                Picture
                                            </Label>
                                            <FilePond
                                                files={files}
                                                onupdatefiles={set_files}
                                                allowMultiple={false}
                                                name='employee_picture'
                                                className='filepond filepond-input'
                                                labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'employee_picture',
                                                        errors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='employee_note'
                                                className='form-label'>
                                                Note
                                            </Label>
                                            <textarea
                                                value={employee_note}
                                                onChange={(e) =>
                                                    set_employee_note(
                                                        e.target.value
                                                    )
                                                }
                                                name='Employees[employee_note]'
                                                className='form-control'
                                                id='employee_note'
                                                rows='3'></textarea>
                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='status'
                                                className='form-label'>
                                                Status
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Employees[employee_status]'
                                                    onChange={() =>
                                                        set_employee_status(
                                                            employee_status ===
                                                                0
                                                                ? 1
                                                                : 0
                                                        )
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        employee_status
                                                    }
                                                    value={employee_status}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                disabled={form_processing}
                                                color={
                                                    employee_id
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    {form_processing && (
                                                        <Spinner
                                                            size='sm'
                                                            className='flex-shrink-0 me-2'></Spinner>
                                                    )}
                                                    <span className='flex-grow-1'>
                                                        {employee_id
                                                            ? 'Update'
                                                            : 'Add'}{' '}
                                                        Employee
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default Employees;
