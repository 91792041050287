import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';

//import images
import avatar from '../../assets/images/users/user-dummy-img.jpg';

const ProfileDropdown = () => {
    const { USER } = useSelector((state) => ({
        USER: state.User,
    }));
    console.log('USER', USER);

    // const [userName, setUserName] = useState('Admin');

    // useEffect(() => {
    //     if (sessionStorage.getItem('authUser')) {
    //         const obj = JSON.parse(sessionStorage.getItem('authUser'));
    //         // setUserName(user.first_name || obj.data.first_name || 'Admin');
    //     }
    // }, [userName, USER]);

    // console.log('USER', USER);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    return (
        <React.Fragment>
            <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className='ms-sm-3 header-item topbar-user'>
                <DropdownToggle tag='button' type='button' className='btn'>
                    <span className='d-flex align-items-center'>
                        <img
                            className='rounded-circle header-profile-user'
                            src={
                                USER.profile
                                    ? `${process.env.REACT_APP_API_URL}/web/attachments/centers/${USER.profile.center_id}/${USER.profile.employee_picture}`
                                    : avatar
                            }
                            alt='Header Avatar'
                        />
                        <span className='text-start ms-xl-2'>
                            <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>
                                {USER.full_name}
                            </span>
                            <span className='d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text'>
                                {USER.role_name}
                            </span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className='dropdown-menu-end'>
                    <h6 className='dropdown-header'>
                        Welcome {USER.full_name}!
                    </h6>
                    <DropdownItem href='/profile'>
                        <i className='mdi mdi-account-circle text-muted fs-16 align-middle me-1'></i>
                        <span className='align-middle'>Profile</span>
                    </DropdownItem>
                    {/* <DropdownItem href='/apps-chat'>
                        <i className='mdi mdi-message-text-outline text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Messages</span>
                    </DropdownItem>
                    <DropdownItem href='/apps-tasks-kanban'>
                        <i className='mdi mdi-calendar-check-outline text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Taskboard</span>
                    </DropdownItem> */}
                    {/* <DropdownItem href='/pages-faqs'>
                        <i className='mdi mdi-lifebuoy text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Help</span>
                    </DropdownItem> */}
                    <div className='dropdown-divider'></div>
                    {/* <DropdownItem href='/pages-profile'>
                        <i className='mdi mdi-wallet text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>
                            Balance : <b>$5971.67</b>
                        </span>
                    </DropdownItem> */}
                    {/* <DropdownItem href='/pages-profile-settings'>
                        <span className='badge bg-soft-success text-success mt-1 float-end'>
                            New
                        </span>
                        <i className='mdi mdi-cog-outline text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Settings</span>
                    </DropdownItem>
                    <DropdownItem href='/auth-lockscreen-basic'>
                        <i className='mdi mdi-lock text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle'>Lock screen</span>
                    </DropdownItem> */}
                    <DropdownItem href='/logout'>
                        <i className='mdi mdi-logout text-muted fs-16 align-middle me-1'></i>{' '}
                        <span className='align-middle' data-key='t-logout'>
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
