const iniState = {
    getLanguages: [],
    getLangLabels: [],
    defaultLanguage: { lang_id: 1, lang_name: 'English', lang_abv: 'Eng', lang_flag: "US", lang_orientation: 0, lang_column: 'english' },
    loadingLanguages: false,
    loadingLabelLanguages: false,
}
const Language = (state = iniState, action) => {
    switch (action.type) {
        case 'DEFAULT_LANGUAGE':
            return {
                ...state,
                defaultLanguage: action.payload,
            }
        case 'LOADING_LANGUAGES_DATA':
            return {
                ...state,
                loadingLanguages: true
            }
        case 'LOADING_LABELS_DATA':
            return {
                ...state,
                loadingLabelLanguages: true,
            }
        case 'LANGUAGES':
            return {
                ...state,
                getLanguages: action.payload,
                loadingLanguages: false
            }
        case 'LABEL_LANGUAGES':
            return {
                ...state,
                getLangLabels: action.payload,
                loadingLabelLanguages: false
            }
        default: {
            return state
        }
    }
}


export default Language