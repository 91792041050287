import { toast } from 'react-toastify';
import axios from 'axios';
import FeatherIcon from 'feather-icons-react';
import { axiosGET } from './datatable_helper';
import { store, persistor } from '../store';
var Buffer = require('buffer/').Buffer

// Validate Numbers
export const justNums = (val, float = false) => {
    let numRegex = /^[0-9\b]+$/;
    if (float) {
        numRegex = /^[0-9]*\.?[0-9]*$/;
    }
    if (val === '' || numRegex.test(val)) {
        return true;
    }
};

// Handle form error
export const handleFormError = (field_name, errorsObj) => {
    if (errorsObj !== null && errorsObj !== undefined && errorsObj !== false) {
        if (errorsObj[field_name] !== undefined) {
            return { show: true, msg: errorsObj[field_name][0] };
        }
    }
    return { show: false, msg: '' };
};

// Toastr
export const showToast = (msg, nature) => {
    toast(msg, {
        position: 'top-center',
        hideProgressBar: true,
        closeOnClick: true,
        className: 'bg-' + nature + ' text-white',
    });
};

// Get roles
export const getRoles = () => {
    return axiosGET('authentication/get-roles');
};

// Get countries
export const getCountries = () => {
    return axiosGET('authentication/get-countries');
};

export const getDDLOptions = (data, option_val, option_label) => {
    let result = [];
    data.map((v, i) => {
        result.push({ value: v[option_val], label: v[option_label] });
    });
    return result;
};

// In-Table thumbnail
export const Thumbnail = (props) => {
    let state = store.getState()
    const path =
        process.env.REACT_APP_API_URL +
        '/web/attachments/centers/' +
        state.Center.center_id +
        '/' +
        props.moduleName +
        '/' +
        props.fileName;

    if (props.fileName !== null) {
        return (
            <img
                alt='Thumbnail'
                src={path}
                className='avatar-md rounded'
                style={{ ...props.style, width: '35px', height: '35px' }}
            />
        );
    } else {
        return (
            <div
                style={{
                    width: '35px',
                    height: '35px',
                    display: 'flex',
                    background: '#F3F6F9',
                    borderRadius: 3,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <FeatherIcon icon='user' />
            </div>
        );
    }
};



//var sellerNameBuf = getTLVForValue("1","Ahmad Seller")
const getTLVForValue = (tagNum, tagValue) => {
    console.log('tagNum, tagValue', tagNum, tagValue);
    var tagBuf = Buffer.from([tagNum], 'utf8')
    var tagValueLenBuf = Buffer.from([tagValue.length], 'utf8')
    var tagValueBuf = Buffer.from(tagValue, 'utf8')
    var bufsArray = [tagBuf, tagValueLenBuf, tagValueBuf]
    return Buffer.concat(bufsArray)
}
//1.Sellers's Name
//2.VAT registration NUM
//3.TimeStamp e.g 2022-04-25T15:30:00Z
//4.Invoice Total with VAT
//4.VAT Total
export const generateQRCode = (sellerName, vatRegistartionName, timeStamp, taxTotalName, vatTotal) => {
    // console.log('buffer', buffer,);
    var sellerNameBuf = getTLVForValue("1", sellerName)
    var vatRegistartionNameBuf = getTLVForValue("2", vatRegistartionName)
    var timeStampBuf = getTLVForValue("3", timeStamp)
    var taxTotalNameBuf = getTLVForValue("4", taxTotalName)
    var vatTotalBuf = getTLVForValue("5", vatTotal)
    console.log('sellerNameBuf,vatRegistartionNameBuf,timeStampBuf,taxTotalNameBuf,vatTotalBuf', sellerNameBuf, vatRegistartionNameBuf, timeStampBuf, taxTotalNameBuf, vatTotalBuf);
    var tagsBufsArray = [sellerNameBuf, vatRegistartionNameBuf, timeStampBuf, taxTotalNameBuf, vatTotalBuf]//hashedXMLBuf,keyBuf,signatureBuf
    var qrCodeBuf = Buffer.concat(tagsBufsArray)
    var qrCodeB64 = qrCodeBuf.toString('base64')
    return qrCodeB64
}