import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormFeedback,
    Label,
    Input,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    ButtonGroup,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import {
    justNums,
    handleFormError,
    showToast,
} from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import {
    axiosPOST,
    CSVHeader,
    PDFExport,
    tableFetch,
    CSVExport,
    dtColumnConfig,
    axiosGET,
} from '../../helpers/datatable_helper';
import Swal from 'sweetalert2';
import moment from 'moment';
import { translate } from '../../helpers/language_helper';

const Services = () => {
    document.title = 'Services | Kinderiffic';
    const offCanvasWidth = '35%';

    // States-E-Module
    const { USER, CENTER, layoutModeType } = useSelector((state) => ({
        USER: state.User,
        CENTER: state.Center,
        layoutModeType: state.Layout.layoutModeType,
    }));

    console.log('layoutModeType', layoutModeType);
    const [stateDT, setStateDT] = useState({
        // START DT START
        // THIS MUST BE DEFINED IF DATATABLES ARE BEING USED FOR SHOW/HIDE COLS
        dtCols: {},
        dtSort: {
            field: 'services_id',
            dir: 'DESC',
        },
        dtData: [],
        dtExportCSV: [],
        dtTotalRows: 0,
        dtPerPage: 10,
        dtCurrentPage: 1,
        dtSearchKeyword: '',
        statusAll: false,
        loading: false,
        // START DT END
    });

    const [state, setState] = useState({
        form_processing: false,
        services_id: 0,
        services_name: '',
        services_desc: '',
        services_fee: '',
        services_cost: '',
        services_vat: '',
        tax_exempt: 0,
        services_monthly: 0,
        services_type: null,
        services_minutes: 60,
        services_status: 1,
        loading: false,
        validationErrors: null,
        offCanvasOpen: false,
    });

    const typeOptions = [
        { value: 'Services', label: 'Services' },
        { value: 'Inventory', label: 'Inventory' },
        { value: 'Resources', label: 'Resources' },
        { value: 'Expense', label: 'Expense' },
    ];

    //START FOR CALLBACK STATES
    const myCallbacksList = useRef([]);
    const setStateWCall = (newState, callback) => {
        setState(newState);
        if (callback) myCallbacksList.current.push(callback);
    };
    useEffect(() => {
        myCallbacksList.current.forEach((callback) => callback(state));
        myCallbacksList.current = [];
    }, [state]);
    //END FOR CALLBACK STATES

    // Handle submit
    let handleSubmitReq;
    const handleSubmit = async (e) => {
        e.preventDefault();
        // set_form_processing(true);
        setStateWCall({ ...state, form_processing: true }, async (state) => {
            const dataForm = document.getElementById('data-form');
            const fData = new FormData(dataForm);
            fData.append('center_id', USER.center_id);
            fData.append('services_id', state.services_id);
            fData.append('Services[center_id]', USER.center_id);
            fData.set(
                'Services[services_cost]',
                state.services_cost === '' ? 0 : state.services_cost
            );
            fData.set(
                'Services[services_vat]',
                state.services_vat === '' ? 0 : state.services_vat
            );
            fData.set('Services[tax_exempt]', state.tax_exempt);
            fData.set('Services[services_monthly]', state.services_monthly);
            fData.set('Services[services_status]', state.services_status);

            if (handleSubmitReq) {
                handleSubmitReq.abort();
            }
            handleSubmitReq = new AbortController();
            let URL = 'services/';
            URL += state.services_id ? 'update' : 'insert';
            const res = await axiosPOST(URL, fData, {}, handleSubmitReq);
            console.log(URL, res);
            if (res) {
                if (res.type) {
                    showToast(res.message, 'success');
                    setState({
                        ...state,
                        services_id: 0,
                        services_name: '',
                        services_desc: '',
                        services_fee: '',
                        services_cost: '',
                        services_vat: '',
                        tax_exempt: 0,
                        services_monthly: 0,
                        services_status: 1,
                        services_type: null,
                        form_processing: false,
                        offCanvasOpen: false,
                        validationErrors: null,
                    });
                    fetchData(stateDT.dtCurrentPage);
                } else {
                    showToast(res.message, 'danger');
                    setState({
                        ...state,
                        validationErrors: res.data,
                        form_processing: false,
                    });
                }
            }
        });
    };

    // Handle minutes
    const handleMinutes = (mins, action) => {
        if (action === null) {
            // set_services_minutes(parseInt(mins));
            setState({ ...state, services_minutes: parseInt(mins) });
        } else {
            if (action) {
                // set_services_minutes(parseInt(mins + 1));
                setState({ ...state, services_minutes: parseInt(mins + 1) });
            } else {
                // set_services_minutes(parseInt(mins - 1));
                setState({ ...state, services_minutes: parseInt(mins - 1) });
            }
        }
    };

    // Reset form
    const resetForm = (offCanvasOpen) => {
        setState({
            ...state,
            services_id: 0,
            services_name: '',
            services_desc: '',
            services_fee: '',
            services_cost: '',
            services_vat: '',
            tax_exempt: 0,
            services_status: 1,
            services_type: null,
            validationErrors: null,
            form_processing: false,
            offCanvasOpen: offCanvasOpen,
        });
    };

    // Edit
    const editThis = (row) => {
        const formFields = [
            'services_id',
            'services_name',
            'services_fee',
            'services_cost',
            'services_type',
            'services_minutes',
            'services_vat',
            'tax_exempt',
            'services_desc',
            'services_status',
        ];
        let newState = { ...state };
        formFields.map((item, index) => {
            if ('services_type' === item) {
                newState = {
                    ...newState,
                    [item]: {
                        value: row[item],
                        label: row[item],
                    },
                };
            } else if ('tax_exempt' === item || 'services_status' === item) {
                newState = { ...newState, [item]: parseInt(row[item]) };
            } else {
                newState = { ...newState, [item]: row[item] };
            }
        });
        setState({ ...newState, offCanvasOpen: true });
    };

    // Delete
    let deleteThisReq;
    const deleteThis = (id) => {
        Swal.fire({
            title: translate('DELETE!'),
            text: translate('Are you sure!'),
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#f06548',
            confirmButtonText: translate('Yes, Delete it!'),
        }).then(async (res) => {
            if (res.value) {
                const fData = new FormData();
                fData.append('services_id', id);
                fData.append('center_id', USER.center_id);
                if (deleteThisReq) {
                    deleteThisReq.abort();
                }
                deleteThisReq = new AbortController();
                const res = await axiosPOST(
                    'services/delete',
                    fData,
                    {},
                    deleteThisReq
                );
                console.log('/services/delete', res.type);
                if (res) {
                    if (res.type) {
                        showToast(res.message, 'danger');
                        fetchData(stateDT.dtCurrentPage);
                    } else {
                        showToast(res.message, 'danger');
                        setState({ ...state, validationErrors: res.data });
                    }
                }
            }
        });
    };

    // Datatable: columns
    // BASED ON THESE STATES IT WILL FETCH THE DATA AGAIN
    useEffect(() => {
        fetchData(stateDT.dtCurrentPage);
    }, [stateDT.dtSort, stateDT.dtSearchKeyword, stateDT.statusAll]);
    const dtColumns = [
        {
            ...dtColumnConfig('services_id', stateDT),
            name: translate('Actions'),
            selector: (row) => (
                <React.Fragment>
                    <Button
                        title='Edit'
                        onClick={() => editThis(row)}
                        color='secondary'
                        className='btn-icon btn-sm me-1'>
                        <i className='ri-pencil-fill' />
                    </Button>
                    <Button
                        title='Delete'
                        onClick={() => deleteThis(row.services_id)}
                        color='danger'
                        className='btn-icon btn-sm'>
                        <i className='ri-delete-bin-5-line' />
                    </Button>
                </React.Fragment>
            ),
            width: '100px',
        },
        {
            ...dtColumnConfig('services_status', stateDT),
            name: translate('Status'),
            width: '90px',
            selector: (row) => (
                <div className='row-checkbox'>
                    <div
                        className='form-check form-switch form-switch-success form-switch-md'
                        dir='ltr'>
                        <Input
                            value={row.services_status}
                            type='checkbox'
                            className='form-check-input'
                            defaultChecked={parseInt(row.services_status)}
                            onChange={() => handleToggleStatus(row.services_id)}
                        />
                    </div>
                </div>
            ),
        },
        {
            ...dtColumnConfig('services_name', stateDT),
            name: translate('Name'),
        },
        { ...dtColumnConfig('services_fee', stateDT), name: translate('Fee') },
        {
            ...dtColumnConfig('services_cost', stateDT),
            name: translate('Cost'),
        },
        {
            ...dtColumnConfig('services_type', stateDT),
            name: translate('Type'),
        },
        {
            ...dtColumnConfig('services_minutes', stateDT),
            name: translate('Daily Minutes'),
        },
        { ...dtColumnConfig('services_vat', stateDT), name: translate('VAT') },
        {
            ...dtColumnConfig('services_monthly', stateDT),
            name: translate('Monthly Subscription'),
            selector: (row) =>
                row.services_monthly == '0' ? (
                    <span className='badge bg-danger'>{translate('No')}</span>
                ) : (
                    <span className='badge bg-success'>{translate('Yes')}</span>
                ),
        },
        {
            ...dtColumnConfig('tax_exempt', stateDT),
            name: translate('Tax Exempt'),
            selector: (row) =>
                row.tax_exempt == '0' ? (
                    <span className='badge bg-danger'>{translate('No')}</span>
                ) : (
                    <span className='badge bg-success'>{translate('Yes')}</span>
                ),
        },
    ];
    // Datatable: header component
    const subHeaderComponent = (
        <>
            <div
                className='form-check form-switch form-switch-success form-switch-md me-auto'
                style={{ marginLeft: '112px' }}>
                <Input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='switchStatusAll'
                    defaultChecked={stateDT.statusAll}
                    onChange={() =>
                        setStateDT({
                            ...stateDT,
                            statusAll: !stateDT.statusAll,
                        })
                    }
                />
                <Label className='form-check-label' for='switchStatusAll'>
                    {' '}
                    {stateDT.statusAll
                        ? translate('Active')
                        : translate('All')}{' '}
                </Label>
            </div>
            {/* CSV,PDF DOWNLOAD BTNS */}
            <ButtonGroup className='btn btn-sm' size='sm'>
                <CSVLink
                    headers={CSVHeader(dtColumns)}
                    filename={'csv_' + moment().unix() + '.csv'}
                    data={stateDT.dtExportCSV}
                    asyncOnClick={true}
                    onClick={(event, done) =>
                        CSVExport(
                            dtColumns,
                            stateDT.dtData,
                            stateDT,
                            setStateDT,
                            done
                        )
                    }
                    className='btn btn-primary btn-sm'>
                    {translate('CSV')}
                </CSVLink>
                <Button
                    className='btn btn-primary btn-sm'
                    onClick={() => PDFExport(dtColumns, stateDT.dtData)}>
                    {translate('PDF')}
                </Button>
            </ButtonGroup>

            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light btn-sm'
                    id='dropdownMenuButton'>
                    {translate('Columns Visibility')}{' '}
                    <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {dtColumns.map(
                        (item, index) =>
                            item.key != null && (
                                <DropdownItem
                                    key={index}
                                    onClick={() =>
                                        setStateDT({
                                            ...stateDT,
                                            dtCols: {
                                                ...stateDT.dtCols,
                                                [item.key]:
                                                    !stateDT.dtCols[item.key],
                                            },
                                        })
                                    }
                                    className={item.omit ? 'text-muted' : ''}>
                                    <FeatherIcon
                                        className='me-1'
                                        icon={item.omit ? 'eye-off' : 'eye'}
                                        size={14}
                                    />{' '}
                                    {item.name}
                                </DropdownItem>
                            )
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon right'>
                <Input
                    value={stateDT.dtSearchKeyword}
                    type='text'
                    className='form-control form-control-sm form-control-icon'
                    id='iconleftInput'
                    placeholder={translate('Search here...')}
                    onChange={(e) =>
                        setStateDT({
                            ...stateDT,
                            dtSearchKeyword: e.target.value,
                        })
                    }
                />
                {stateDT.dtSearchKeyword != '' ? (
                    <i
                        onClick={() =>
                            setStateDT({ ...stateDT, dtSearchKeyword: '' })
                        }
                        className='mdi mdi-close-thick search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                ) : (
                    <i
                        className='mdi mdi-magnify search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                )}
            </div>
        </>
    );

    // Datatable: fetch data
    let fetchDataReq;
    const fetchData = async (page, newPerPage = stateDT.dtPerPage) => {
        setStateDT({ ...stateDT, loading: true });
        if (fetchDataReq) {
            fetchDataReq.abort();
        }
        fetchDataReq = new AbortController();
        let parameters = {
            center_id: USER.center_id,
            offset: (page - 1) * newPerPage,
            order: stateDT.dtSort.field,
            dir: stateDT.dtSort.dir,
            page: page,
            limit: newPerPage,
            search: stateDT.dtSearchKeyword,
            only_active: stateDT.statusAll,
        };

        const res = await tableFetch(
            'services/fetch',
            fetchDataReq,
            parameters
        );
        if (res) {
            console.log('tableFetchres', res);
            setStateDT({
                ...stateDT,
                dtData: res.data,
                dtTotalRows: res.total_count_filtered,
                loading: false,
                dtCurrentPage: page,
                dtPerPage: newPerPage,
            });
        } else {
            setStateDT({ ...stateDT, loading: false });
        }
    };

    // Datatable: toggle status
    let handleToggleStatusReq;
    const handleToggleStatus = async (id) => {
        const fData = new FormData();
        fData.append('center_id', USER.center_id);
        fData.append('services_id', id);
        if (handleToggleStatusReq) {
            handleToggleStatusReq.abort();
        }
        handleToggleStatusReq = new AbortController();
        const res = await axiosPOST(
            'services/toggle-status',
            fData,
            {},
            handleToggleStatusReq
        );
        if (res) {
            if (res.type) {
                showToast(res.message, 'success');
                fetchData(stateDT.dtCurrentPage);
            } else {
                showToast(res.message, 'danger');
                setState({ ...state, validationErrors: res.data });
            }
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    {translate('Services')}
                                </h4>
                                <Button
                                    onClick={() => resetForm(true)}
                                    color='success'>
                                    <span>{translate('Add Service')}</span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={dtColumns}
                                data={stateDT.dtData}
                                progressPending={stateDT.loading}
                                pagination
                                paginationServer
                                paginationTotalRows={stateDT.dtTotalRows}
                                onChangePage={(page) => fetchData(page)}
                                onChangeRowsPerPage={(newPerPage, page) =>
                                    fetchData(page, newPerPage)
                                }
                                paginationComponentOptions={{
                                    selectAllRowsItem: true,
                                }}
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={(column, sortDirection) =>
                                    setStateDT({
                                        ...stateDT,
                                        dtSort: {
                                            field: column.key,
                                            dir: sortDirection.toUpperCase(),
                                        },
                                    })
                                }
                                sortServer
                                theme={
                                    layoutModeType == 'dark'
                                        ? 'dark'
                                        : 'default'
                                }
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={state.offCanvasOpen}
                    toggle={() => resetForm(false)}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={() => resetForm(false)}>
                        <span className='m-0 me-2'>
                            {state.services_id
                                ? translate('Update')
                                : translate('Add')}{' '}
                            {translate('Service')}
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            id='data-form'
                            onSubmit={handleSubmit}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_name'
                                                className='form-label'>
                                                {translate('Name')}
                                            </Label>
                                            <Input
                                                name='Services[services_name]'
                                                value={state.services_name}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        services_name:
                                                            e.target.value,
                                                    })
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_name'
                                                invalid={
                                                    handleFormError(
                                                        'services_name',
                                                        state.validationErrors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_name',
                                                        state.validationErrors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_fee'
                                                className='form-label'>
                                                {translate('Fee')}
                                            </Label>
                                            <Input
                                                name='Services[services_fee]'
                                                value={state.services_fee}
                                                onChange={(e) =>
                                                    justNums(e.target.value, true) &&
                                                    setState({
                                                        ...state,
                                                        services_fee:
                                                            e.target.value,
                                                    })
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='services_fee'
                                                invalid={
                                                    handleFormError(
                                                        'services_fee',
                                                        state.validationErrors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_fee',
                                                        state.validationErrors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_cost'
                                                className='form-label'>
                                                {translate('Cost')}
                                            </Label>
                                            <Input
                                                name='Services[services_cost]'
                                                value={state.services_cost}
                                                onChange={(e) =>
                                                    justNums(
                                                        e.target.value,
                                                        true
                                                    ) &&
                                                    setState({
                                                        ...state,
                                                        services_cost:
                                                            e.target.value,
                                                    })
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_cost'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_vat'
                                                className='form-label'>
                                                {translate('VAT')}
                                            </Label>
                                            <Input
                                                name='Services[services_vat]'
                                                value={state.services_vat}
                                                onChange={(e) =>
                                                    justNums(
                                                        e.target.value,
                                                        true
                                                    ) &&
                                                    setState({
                                                        ...state,
                                                        services_vat:
                                                            e.target.value,
                                                    })
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder='0.00'
                                                id='services_vat'
                                            />
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label className='services_type'>
                                                {translate('Type')}
                                            </Label>
                                            <Select
                                                className={
                                                    handleFormError(
                                                        'services_type',
                                                        state.validationErrors
                                                    ).show && 'is-invalid'
                                                }
                                                styles={{
                                                    control: () => ({
                                                        borderColor:
                                                            handleFormError(
                                                                'services_type',
                                                                state.errors
                                                            ).show
                                                                ? '#f06548'
                                                                : '#CFD4DA',
                                                        borderWidth: 1,
                                                        borderStyle: 'solid',
                                                        display: 'flex',
                                                        borderRadius: 5,
                                                    }),
                                                }}
                                                name='Services[services_type]'
                                                value={state.services_type}
                                                onChange={(type) =>
                                                    setState({
                                                        ...state,
                                                        services_type: type,
                                                    })
                                                }
                                                options={typeOptions}
                                                invalid={true}
                                                id='services_type'
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'services_type',
                                                        state.validationErrors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='services_desc'
                                                className='form-label'>
                                                {translate('Description')}
                                            </Label>
                                            <textarea
                                                value={state.services_desc}
                                                onChange={(e) =>
                                                    setState({
                                                        ...state,
                                                        services_desc:
                                                            e.target.value,
                                                    })
                                                }
                                                name='Services[services_desc]'
                                                className='form-control'
                                                id='services_desc'
                                                rows='3'></textarea>
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <div>
                                            <Label className='form-label'>
                                                {translate('Daily Minutes')}
                                            </Label>
                                            <br />
                                            <div className='input-step'>
                                                <button
                                                    type='button'
                                                    className='minus'
                                                    onClick={() =>
                                                        handleMinutes(
                                                            state.services_minutes,
                                                            false
                                                        )
                                                    }>
                                                    –
                                                </button>
                                                <Input
                                                    name='Services[services_minutes]'
                                                    type='number'
                                                    className='product-quantity'
                                                    value={
                                                        state.services_minutes
                                                    }
                                                    style={{ width: '100px' }}
                                                    min='0'
                                                    onChange={(e) =>
                                                        justNums(
                                                            e.target.value
                                                        ) &&
                                                        handleMinutes(
                                                            e.target.value,
                                                            null
                                                        )
                                                    }
                                                />
                                                <button
                                                    type='button'
                                                    className='plus'
                                                    onClick={() =>
                                                        handleMinutes(
                                                            state.services_minutes,
                                                            true
                                                        )
                                                    }>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='taxEx'
                                                className='form-label'>
                                                {translate(
                                                    'Monthly Subscription'
                                                )}
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[services_monthly]'
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            services_monthly:
                                                                state.services_monthly ===
                                                                    0
                                                                    ? 1
                                                                    : 0,
                                                        })
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        state.services_monthly
                                                    }
                                                    value={
                                                        state.services_monthly
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='taxEx'
                                                className='form-label'>
                                                {translate('Tax Exempt')}
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[tax_exempt]'
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            tax_exempt:
                                                                state.tax_exempt ===
                                                                    0
                                                                    ? 1
                                                                    : 0,
                                                        })
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        state.tax_exempt
                                                    }
                                                    value={state.tax_exempt}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={2}>
                                        <div className='mb-3'>
                                            <Label
                                                htmlFor='status'
                                                className='form-label'>
                                                {translate('Status')}
                                            </Label>
                                            <div
                                                className='form-check form-switch form-switch-lg pt-1'
                                                dir='ltr'>
                                                <Input
                                                    name='Services[services_status]'
                                                    onChange={(e) =>
                                                        setState({
                                                            ...state,
                                                            services_status:
                                                                state.services_status ===
                                                                    0
                                                                    ? 1
                                                                    : 0,
                                                        })
                                                    }
                                                    type='checkbox'
                                                    className='form-check-input'
                                                    id='customSwitchsizelg'
                                                    defaultChecked={
                                                        state.services_status
                                                    }
                                                    value={
                                                        state.services_status
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                type='submit'
                                                disabled={state.form_processing}
                                                color={
                                                    state.services_id
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    {state.form_processing && (
                                                        <Spinner
                                                            size='sm'
                                                            className='flex-shrink-0 me-2'></Spinner>
                                                    )}
                                                    <span className='flex-grow-1'>
                                                        {state.services_id
                                                            ? translate(
                                                                'Update'
                                                            )
                                                            : translate(
                                                                'Add'
                                                            )}{' '}
                                                        {translate('Service')}
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default Services;
