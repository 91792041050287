import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    Input,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { showToast } from '../../helpers/common_helper';
import { tableFetch } from '../../helpers/datatable_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { translate } from '../../helpers/language_helper';

const Statement = () => {
    document.title = 'Statement | Kinderiffic';
    const CSVLinkEl = useRef(null);

    // States-E-Module
    const { user, center, layoutModeType } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
        layoutModeType: state.Layout.layoutModeType,
    }));

    // States-E-Form
    const [parentCode, setParentCode] = useState('');
    const [balance, setBalance] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalDebit, setTotalDebit] = useState(0);
    const [statement, setStatement] = useState([]);

    const [state, setState] = useState({
        errors: null,
        searching: false,
    });

    // Cancel
    const cancel = () => {
        setParentCode('');
        setBalance(0);
        setTotalCredit(0);
        setTotalDebit(0);
        setStatement([]);
    };

    // Get statement
    const getParentStatement = (e) => {
        e.preventDefault();

        setState({ ...state, searching: true });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/parent-statement/get-statement?auth_key=${user.auth_key}&center_id=${user.center_id}&search=${parentCode}`
            )
            .then((res) => {
                console.log(res);
                if (res.type) {
                    setBalance(res.data.balance);
                    setTotalCredit(res.data.totalCredit);
                    setTotalDebit(res.data.totalDebit);
                    setStatement(res.data.statement);
                    setState({
                        ...state,
                        searching: false,
                    });
                } else {
                    showToast(res.message, 'warning');
                    setState({
                        ...state,
                        searching: false,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setState({
                    ...state,
                    searching: false,
                });
            });
    };

    // Statement row
    const statementRow = statement.map((item, index) => {
        return (
            <tr key={index}>
                <td className='fw-medium'>{item.jvtype_id}</td>
                <td>{item.jvtype_desc}</td>
                <td>{item.receipt_num}</td>
                <td>{moment(item.datetime).format('DD-MM-YYYY')}</td>
                <td className='text-danger'>
                    {item.transactionType == 'Debit' && item.amount}
                </td>
                <td className='text-success'>
                    {item.transactionType == 'Credit' && item.amount}
                </td>
            </tr>
        );
    });

    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    {translate('Statement')}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className='g-3'>
                                <Col md={4}>
                                    <Form
                                        id='data-form'
                                        onSubmit={(e) => getParentStatement(e)}>
                                        <div className='input-group'>
                                            <input
                                                value={parentCode}
                                                type='text'
                                                id='iconleftInput'
                                                placeholder={translate(
                                                    'Search with parent code'
                                                )}
                                                onChange={(e) =>
                                                    setParentCode(
                                                        e.target.value
                                                    )
                                                }
                                                className='form-control'
                                            />
                                            <button
                                                type='submit'
                                                className='btn btn-danger'>
                                                SEARCH
                                            </button>
                                            <Button
                                                onClick={() => cancel()}
                                                color='primary'
                                                disabled={
                                                    parentCode === 0 && true
                                                }>
                                                {translate('CANCEL')}
                                            </Button>
                                        </div>
                                    </Form>
                                </Col>
                                <Col
                                    md={8}
                                    className='justify-content-end align-items-center d-flex'>
                                    <h5 className='mb-3'>
                                        <strong>{translate('Balance:')}</strong>{' '}
                                        {balance.toFixed(2)}
                                    </h5>
                                </Col>
                            </Row>
                            {statement.length > 0 && (
                                <Row>
                                    <Col md={12} className='mt-3'>
                                        <div className='table-responsive'>
                                            <Table className='table-nowrap mb-0'>
                                                <thead className='table-light'>
                                                    <tr>
                                                        <th scope='col'>
                                                            {translate('ID')}
                                                        </th>
                                                        <th scope='col'>
                                                            {translate(
                                                                'Receipt #'
                                                            )}
                                                        </th>
                                                        <th scope='col'>
                                                            {translate(
                                                                'Description'
                                                            )}
                                                        </th>
                                                        <th scope='col'>
                                                            {translate('Date')}
                                                        </th>
                                                        <th scope='col'>
                                                            {translate('Debit')}
                                                        </th>
                                                        <th scope='col'>
                                                            {translate(
                                                                'Credit'
                                                            )}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>{statementRow}</tbody>
                                                <tfoot className='table-light'>
                                                    <tr>
                                                        <td colSpan='4'>
                                                            Total
                                                        </td>
                                                        <td>
                                                            {totalDebit.toFixed(
                                                                2
                                                            )}
                                                        </td>
                                                        <td>
                                                            {totalCredit.toFixed(
                                                                2
                                                            )}
                                                        </td>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Statement;
