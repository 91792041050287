import React from 'react';
import { Redirect } from 'react-router-dom';

//Dashboard
import DashboardEcommerce from '../pages/DashboardEcommerce';

//login
import QrRead from '../pages/Authentication/QrRead';
import Policy from '../pages/Authentication/Policy';
import Login from '../pages/Authentication/Login';
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword';
import Logout from '../pages/Authentication/Logout';
import Register from '../pages/Authentication/Register';
import Services from '../pages/Services';
import Employees from '../pages/Employees';
import Invoices from '../pages/Invoices';
import Center from '../pages/Center';
import InvoiceDetails from '../pages/Invoices/invoice-details';
import ReceiptDetails from '../pages/Receipts/receipt-details';
import VerifyEmail from '../pages/Authentication/VerifyEmail';
import UserTimeline from '../pages/user-timeline';
import Receipts from '../pages/Receipts';
import Statement from '../pages/Statement';

// REPORTS
import Collection from '../pages/Reports/Collection';
import Visitors from '../pages/Reports/Visitors';
import { default as RInvoices } from '../pages/Reports/Invoices';

// NAVIGATION
import NavTree from '../pages/Navigation/NavTree';


// const Services = React.lazy(() => import("../pages/Services"));
// const Employees = React.lazy(() => import("../pages/Employees"));
// const Invoices = React.lazy(() => import("../pages/Invoices"));
// const Center = React.lazy(() => import("../pages/Center"));
// const InvoiceDetails = React.lazy(() => import("../pages/Invoices/invoice-details"));

const authProtectedRoutes = [
    // { path: "/dashboard-analytics", component: DashboardAnalytics },
    // { path: "/dashboard-crm", component: DashboardCrm },
    { path: '/dashboard', component: DashboardEcommerce },
    { path: '/index', component: DashboardEcommerce },
    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: '/services', component: Services },
    { path: '/employees', component: Employees },
    { path: '/invoices', component: Invoices },
    { path: '/receipts', component: Receipts },
    { path: '/center', component: Center },
    { path: '/invoice-details', component: InvoiceDetails },
    { path: '/receipt-details', component: ReceiptDetails },
    { path: '/user-timeline', component: UserTimeline },
    { path: '/statement', component: Statement },
    { path: '/report/collection', component: Collection },
    { path: '/report/visitors', component: Visitors },
    { path: '/report/invoices', component: RInvoices },
    { path: '/navigation/nav-tree', component: NavTree },
    {
        path: '/',
        exact: true,
        component: () => <Redirect to='/dashboard' />,
    },
];

const publicRoutes = [
    // Authentication Page
    { path: '/logout', component: Logout },
    { path: '/login', component: Login },
    { path: '/forgot-password', component: ForgetPasswordPage },
    { path: '/register', component: Register },
    { path: '/policy', component: Policy },
    { path: '/qr-read/:id', component: QrRead },
    { path: '/verify-email/:user_name', component: VerifyEmail },
];

export { authProtectedRoutes, publicRoutes };
