import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link, useParams } from 'react-router-dom';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import logoLight from '../../assets/images/logo-light.png';
import { axiosPOST } from '../../helpers/datatable_helper';
import { showToast } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';

const Policy = (props) => {

    document.title = 'Kinderiffic';
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className='auth-page-content'>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                                    <div>
                                        <Link
                                            to='/'
                                            className='d-inline-block auth-logo'>
                                            <img
                                                src={logoLight}
                                                alt=''
                                                height='80'
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Card className='mt-4'>
                                    <CardBody className='p-4'>
                                        <div className='text-left mt-2'>
                                            <p>Last updated on 09-Aug-2022</p>
                                            <h3>For Cookies</h3>
                                            <p>We use tools, such as cookies, to enable essential services and functionality on our site and mobile apps
                                                and to collect data on how visitors interact with our site, products and services. By clicking Accept, you
                                                agree to our use of these tools for advertising, analytics and support.</p>
                                            <h3>Privacy Policy</h3>
                                            <p>Kinderiffic - PRIVACY POLICY</p>
                                            <p>Last Revised: 09-Aug-2022</p>
                                            <p>Your privacy is highly important to Kinderiffic.
                                                As a result, we only collect and use personal information as needed to deliver our products, services,
                                                websites, and mobile applications, as well as to communicate with you about them (collectively, our
                                                "Services").</p>
                                            <p>The personal information gathered relates to your interactions with our site features or mobile app
                                                features inside the Services; Internet Protocol (IP) address, to determine your language preference; browser
                                                type the date and time the Services were used; device type and settings; operating system used; application
                                                IDs, unique device identifiers; and error data.
                                                These technologies also collect data such as metadata, log files, page load time, and server response time to
                                                optimize DNS resolution, network routing, and server settings to monitor website performance and enhance our
                                                systems.</p>

                                            <p>Other information, not collected by Kinderiffic, rather input by schools, nurseries, students, parents or
                                                any other user as “Users” on Kinderiffic, may contain:</p>
                                            <p>
                                                <ul>
                                                    <li>Name</li>
                                                    <li>Address</li>
                                                    <li>Social Security number</li>
                                                    <li>Telephone number</li>
                                                    <li>Date of birth</li>
                                                    <li>Email address</li>
                                                    <li>Billing and payment information</li>
                                                    <li>Other data collected that could directly or indirectly identify you.</li>
                                                </ul>
                                            </p>
                                            <p>Our Privacy Policy describes not only how and why we collect and use your personal information, but also
                                                how you can view, update, or otherwise manage your personal information.</p>
                                            <h3 >What information do we gather, how do we collect it, and why do we collect it?
                                            </h3>
                                            <p>Much of what you could consider personal information is acquired directly from you or your nursery
                                                management when you create an account for yourself or register for the Kinderiffic.com App.</p>

                                            <p>However, when providing our Services to you, we collect additional information to ensure that they are
                                                delivered in a timely and efficient manner.These collection methods may not be as evident to you, so we
                                                thought we'd point them out and explain what they are (as they change from time to time):
                                            </p>
                                            <p>Cookies and other similar technologies on our websites and mobile applications enable us to track your
                                                browsing activity, such as the links you click and the pages you see,
                                                Internet Protocol (IP) address, to determine your language preference; browser type the date and time the
                                                Services were used; device type and settings; operating system used; application IDs, unique device
                                                identifiers; and error data.</p>
                                            <h3 >How we make use of data.</h3>
                                            <p>We collect minimal data and limit its use and purpose to those who granted the permission to access
                                                exclusively by accepting out terms and privacy policies, and we use collected data to make sure that our
                                                Services are delivered, improved, updated, and enhanced.
                                                We gather information on your access to, use of, and interactions with our Services.
                                                Further it is used to diagnose issues with the Services and identify any security or compliance concerns,
                                                faults, or needed enhancements, and to detect fraud and abuse of our.</p>
                                            <p>Much of the information gathered is aggregated or statistical information about how people use our
                                                services, and it is not linked to any personally identifiable information.</p>
                                            <h3 >Sharing with 3rd Party.</h3>
                                            <p>We may share your personal information with our corporate family's connected companies, third parties with
                                                whom we've partnered to allow you to integrate their services into our own Services, and trusted third-party
                                                service providers as needed to perform services on our behalf, such as:</p>
                                            <ul>
                                                <li>Processing credit card payments</li>
                                                <li>Serving advertisements</li>
                                                <li>Conducting contests or surveys</li>
                                                <li>Performing analysis of our Services and customers demographics</li>
                                                <li>Communicating with you, such as by way email or survey delivery</li>
                                                <li>Customer relationship management</li>
                                                <li>Security, risk management and compliance</li>
                                                <li>Recruiting support and related services.</li>
                                            </ul>
                                            <p>These third parties (and any subcontractors they may be allowed to use) have agreed not to share, use, or
                                                retain your personal information for any reason other than to provide Services to you.</p>
                                            <h3 >We will also share your information with the following third parties:</h3>
                                            <p>if we sell, buy, merge, are acquired by, or partner with other companies or businesses, or sell some or all
                                                of our assets (whether as a result of liquidation, bankruptcy, or otherwise), in which case we will disclose
                                                your data to the prospective seller or buyer of such business or assets; or if we sell, buy, merge, are acquired
                                                by, or partner with other companies or businesses, in which case we will disclose your data to the prospective
                                                seller or buyer of such business or assets. Your information may be among the assets transferred in such
                                                deals.</p>
                                            <h3 >We may use your data to communicate with you.</h3>
                                            <p>We may contact you directly or through a third-party service provider about products or services for which
                                                you have signed up or registered, such as when transactional or service-related communications are required. If
                                                you give us your approval, we may contact you with offers for other services we think you'll find useful, or if
                                                we're permitted to contact you based on legitimate interests. You do not need to give your consent in order to
                                                buy our products or services. The following are examples of possible contacts:
                                                <ul>
                                                    <li> Email</li>
                                                    <li> Text (SMS) messages</li>
                                                    <li> Telephone calls;</li>
                                                    <li> Messenger applications (e.g. WhatsApp, etc.)</li>
                                                    <li> Automated phone calls or text messages.</li>
                                                </ul>
                                            </p>
                                            <h3 >Transfer of personal information abroad.</h3>
                                            <p>If you utilize our Services, from a country other than the country where our servers are located, your
                                                personal information may be transferred across international borders, which will only be done when necessary for
                                                the performance of our Services, when we have your consent to do so, or subject to the appropriate standard
                                                contractual clauses. Also, when you call us or initiate a chat, we may provide you with support from one of our
                                                global locations outside your country of origin.</p>
                                            <h3 >Compliance with legal, regulatory and law enforcement requests. </h3>
                                            <p>We may disclose any information about you to government or law enforcement officials or private parties as
                                                we, in our sole discretion, believe necessary or appropriate to respond to claims and legal process (such as
                                                subpoena requests), to protect our property and rights or the property and rights of a third party, to protect
                                                the safety of the public or any person, or to prevent or stop activity we consider to be illegal or
                                                unethical.</p>
                                            <p>To the extent we are legally permitted to do so, we may take reasonable steps to notify you if we are
                                                required to provide your personal information to third parties as part of legal process.</p>
                                            <h3 >How we secure, store and retain your data.</h3>
                                            <p>We use generally acknowledged standards to store and protect the personal information we collect,
                                                including encryption where appropriate during transmission and once received and saved.</p>
                                            <p>We keep personal information for as long as it's needed to offer the Services requested, and then for a
                                                variety of other legitimate legal or business reasons.</p>
                                            <p>Media files, videos, PDF’s are stored for a period of 12 months before being deleted off our
                                                servers.</p>
                                            <h3 >How we secure, store and retain your data.</h3>
                                            <p>If you wish or request to delete your personal information and that data is necessary for the products or
                                                services you have been registered, the request will be honored only if it was sent officially by your school
                                                or nursery management and to the extent it is no longer necessary for any Services registered for or required
                                                for our legitimate business purposes or legal or contractual record keeping requirements.</p>
                                            <h3 >Changes to this policy.</h3>
                                            <p>We reserve the right to modify this Privacy Policy at any time. If we change any content of our Privacy
                                                Policy, we will post those changes to this Privacy Policy and any other places we see appropriate, so that you
                                                are aware of what information we collect, how we use it, and under what circumstances, if any, we disclose it.
                                                You will be notified for any material changes to this Privacy Policy, notifications will be posted either on
                                                our mobile App, on our website,by email, or by means of a notice on our home page, at least thirty (30) days
                                                prior to the implementation of the changes.</p>
                                            <h3 >Contact us.</h3>
                                            <p>If you have any questions, concerns, or complaints about our Privacy Policy, or how we handle your
                                                personal data, you may contact our Office by email at contactus@kinderiffic.com </p>

                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Policy);
