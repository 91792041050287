import React, { useEffect, useRef, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormFeedback,
    Label,
    Input,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    ListGroup,
    ListGroupItem,
    Table,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import {
    justNums,
    handleFormError,
    showToast,
    Thumbnail,
    getServices,
} from '../../helpers/common_helper';
import { tableFetch } from '../../helpers/datatable_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { translate } from '../../helpers/language_helper';
var Chance = require('chance');
var chance = new Chance();

const Invoices = () => {
    document.title = 'Invoices | Kinderiffic';
    const offCanvasWidth = '35%';
    const CSVLinkEl = useRef(null);
    const unique_id = uuid();
    // const small_id = unique_id.slice(0, 8);
    const small_id = chance.integer({ min: 100000000000, max: 1000000000000 });

    // States-E-Module
    const { user, center, layoutModeType } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
        layoutModeType: state.Layout.layoutModeType,
    }));
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);

    // States-E-Form
    const [form_processing, set_form_processing] = useState(false);
    const [services_id, set_services_id] = useState(0);
    const [services, setServices] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [parent, setParent] = useState({});
    const [children, setChildren] = useState([]);
    const [searchChildrenKeyword, setSearchChildrenKeyword] = useState('');
    const [servicesDDL, setServicesDDL] = useState([]);
    const [invoiceDraft, setInvoiceDraft] = useState({
        center_id: user.center_id,
        center_timezone: center.center_timezone,
        user_id: user.user_id,
        inv_tax_exempt: 0,
        inv_id: 0,
        jvtype_id: 4,
        parent_id: null,
        invoicedetails: [],
    });

    const [disableSearch, setDisableSearch] = useState(false);

    const [formHasError, setFormHasError] = useState(false);
    const [stateDT, setStateDT] = useState({
        data: [],
        CSVData: [],
        loading: false,
        totalRows: 0,
        perPage: 10,
        currentPage: 1,
        searchKeyword: '',
        sort: {
            field: 'inv_id',
            dir: 'ASC',
        }
    })

    const [state, setState] = useState({

        serviceForm: {
            child_id: null,
            child_name: null,
            child_code: null,
            services_id: null,
            services_qty: 1,
            services_fee: 0,
            services_minutes: 0,
            services_disc: 0,
        },
        errors: null,
        hideInvNumCol: false,
        hideNameCol: false,
        hideCodeCol: false,
        hideAmountCol: false,
        hideVatCol: false,
        hideDateCol: false,
        hidePictureCol: false,
        searching: false,
    });

    // Use effect
    useEffect(() => {
        handleServices();
    }, []);

    const handleServices = async () => {
        const res = await axios.get(
            process.env.REACT_APP_API_URL +
            '/authentication/get-services?auth_key=' +
            user.auth_key +
            '&center_id=' +
            user.center_id +
            '&services_type[]=Services&services_type[]=Resources'
        );

        setServices(res);

        let servicesDDL = [];

        res.map((item) => {
            servicesDDL.push({
                ...item,
                label: item.services_name,
                value: item.services_id,
            });
        });

        setServicesDDL(servicesDDL);
    };

    // Toggle off canvas
    const toggleOffCanvas = () => {
        setOffCanvasOpen(!offCanvasOpen);
    };

    // Handle selects
    const handleService = (obj) => {
        setState({
            ...state,
            serviceForm: { ...state.serviceForm, ...obj },
        });
    };
    const selectFldStyle = {
        control: () => ({
            borderColor: formHasError ? '#f06548' : '#CFD4DA',
            borderWidth: 1,
            borderStyle: 'solid',
            display: 'flex',
            borderRadius: 5,
        }),
    };

    // Handle submit
    const handleSubmit = async (how_to_save) => {
        set_form_processing(true);

        let api_url = process.env.REACT_APP_API_URL + '/invoice/insert';

        if (isEditing) {
            api_url = process.env.REACT_APP_API_URL + '/invoice/update';
        }

        // Distinctive child ids
        var child_ids = [];
        invoiceDraft.invoicedetails.filter((item) => {
            var i = child_ids.findIndex((x) => x == item.child_id);
            if (i <= -1) {
                child_ids.push(item.child_id);
            }
            return null;
        });

        const fData = new FormData();
        fData.append('center_id', user.center_id);
        fData.append('center_timezone', center.center_timezone);
        fData.append('user_id', user.user_id);
        fData.append('inv_tax_exempt', invoiceDraft.inv_tax_exempt);
        fData.append('jvtype_id', invoiceDraft.jvtype_id);
        if (isEditing) {
            fData.append('inv_id', invoiceDraft.inv_id);
        }
        fData.append('inv_paid', how_to_save === 'pay&save' ? 1 : 0);
        fData.append('parent_id', parent.parent_id);
        fData.append('child_ids', JSON.stringify(child_ids));
        fData.append(
            'invoicedetails',
            JSON.stringify(invoiceDraft.invoicedetails)
        );
        try {
            const res = await axios.post(api_url, fData, {
                params: {
                    auth_key: user.auth_key,
                },
            });

            if (res.type) {
                showToast(res.message, 'success');
                fetchData(stateDT.currentPage);
                set_form_processing(false);
                setParent(null);
                setChildren([]);
                setSearchChildrenKeyword('');
                setIsEditing(false);
                setDisableSearch(false);
                setInvoiceDraft({
                    center_id: user.center_id,
                    center_timezone: center.center_timezone,
                    user_id: user.user_id,
                    inv_tax_exempt: 0,
                    inv_id: 0,
                    jvtype_id: 4,
                    parent_id: null,
                    invoicedetails: [],
                });
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setState({ ...state, errors: res.data });
                set_form_processing(false);
            }
        } catch (error) {
            showToast(error, 'danger');
            set_form_processing(false);
        }
    };

    // Cancel
    const cancel = () => {
        setParent({});
        setChildren([]);
        setIsEditing(false);
        setSearchChildrenKeyword('');
        setInvoiceDraft({
            center_id: user.center_id,
            center_timezone: center.center_timezone,
            user_id: user.user_id,
            inv_tax_exempt: 0,
            inv_id: 0,
            jvtype_id: 4,
            parent_id: null,
            invoicedetails: [],
        });
        setDisableSearch(false);
    };

    // Add service
    const handleAddService = (e) => {
        e.preventDefault();

        const filteredServices = services.filter(
            (item) => item.services_id == state.serviceForm.services_id
        );

        if (filteredServices[0] !== undefined) {
            setFormHasError(false);

            let total =
                parseFloat(filteredServices[0].services_fee) *
                state.serviceForm.services_qty;

            console.log('total', total);

            let discount = 0;

            if (state.serviceForm.services_disc > 0) {
                total = total * (1 - state.serviceForm.services_disc / 100);
            }

            console.log('discount', discount);

            // total = total - discount;

            console.log('total', total);

            const vat = total * (filteredServices[0].services_vat / 100);

            console.log('vat', vat);

            invoiceDraft.invoicedetails.push({
                child_id: state.serviceForm.child_id,
                services_id: filteredServices[0].services_id,
                services_name: filteredServices[0].services_name,
                invd_line_amount: parseFloat(filteredServices[0].services_fee),
                invd_line_qty: state.serviceForm.services_qty,
                invd_line_discount: state.serviceForm.services_disc,
                invd_total: total + vat,
                invd_line_vat: vat,
                invd_minutes:
                    parseInt(filteredServices[0].services_minutes) *
                    state.serviceForm.services_qty,
                invd_id: small_id,
            });
            resetForm();
        } else {
            setFormHasError(true);
        }
    };

    // Delete service
    const handleDelService = (invd_id, child_id) => {
        console.log(invd_id, child_id);
        const indexOf = invoiceDraft.invoicedetails.findIndex(
            (item, index) =>
                item.child_id == child_id && item.invd_id == invd_id
        );

        invoiceDraft.invoicedetails.splice(indexOf, 1);

        setInvoiceDraft({ ...invoiceDraft });
    };

    // Reset form
    const resetForm = () => {
        setState({
            ...state,
            serviceForm: {
                ...state.serviceForm,
                services_qty: 1,
                services_fee: 0,
                services_minutes: 0,
                services_id: null,
                services_disc: 0,
            },
        });
        set_form_processing(false);
        setFormHasError(false);
        toggleOffCanvas();
    };

    // Edit
    const editThis = (row) => {
        const inv_id = row.inv_id;

        try {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/invoice/edit?auth_key=${user.auth_key}&inv_id=${inv_id}`
                )
                .then((res) => {
                    console.log(res);
                    if (res.type) {
                        const invoice = res.data.invoice;
                        const invoice_details = res.data.invoicedetails;
                        const parent_code = invoice.parent_code;
                        setInvoiceDraft({
                            inv_id: invoice.inv_id,
                            inv_paid: invoice.inv_paid,
                            center_id: user.center_id,
                            center_timezone: center.center_timezone,
                            user_id: user.user_id,
                            inv_tax_exempt: 0,
                            jvtype_id: 4,
                            parent_id: invoice.parent_id,
                            invoicedetails: invoice_details,
                        });
                        setSearchChildrenKeyword(parent_code);
                        searchChildren(undefined, parent_code);
                        setDisableSearch(true);
                        setIsEditing(true);
                    }
                });
        } catch (error) {
            console.log(error);
        }
    };

    // // Delete
    // const deleteThis = async (id) => {
    //     try {
    //         const fData = new FormData();
    //         fData.append('services_id', id);
    //         fData.append('center_id', user.center_id);

    //         const res = await axios.post(
    //             process.env.REACT_APP_API_URL + '/services/delete',
    //             fData,
    //             {
    //                 params: {
    //                     auth_key: user.auth_key,
    //                 },
    //             }
    //         );

    //         if (res.type) {
    //             showToast(res.message, 'success');
    //             fetchData(stateDT.currentPage);
    //         }

    //         if (!res.type) {
    //             showToast(res.message, 'danger');
    //             setState({ ...state, errors: res.data });
    //         }
    //     } catch (error) {
    //         showToast(error, 'danger');
    //     }
    // };

    // Get children
    const searchChildren = (e, code = undefined) => {
        if (e) {
            e.preventDefault();
        }

        setDisableSearch(true);

        if (code === undefined) {
            code = searchChildrenKeyword;
        }

        setState({ ...state, searching: true });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/invoice/get-children?auth_key=${user.auth_key}&center_id=${user.center_id}&center_timezone=${center.center_timezone}&search=${code}`
            )
            .then((res) => {
                console.log(res);
                if (res.type) {
                    setParent(res.data.parent);
                    setChildren(res.data.children);
                    setState({
                        ...state,
                        searching: false,
                    });
                } else {
                    showToast(res.message, 'warning');
                    setParent(null);
                    setChildren([]);
                    setState({
                        ...state,
                        searching: false,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                setParent(null);
                setChildren([]);
                setState({
                    ...state,
                    searching: false,
                });
            });
    };

    // Service rows
    const servicesTable = (child_id) => {
        const invoiceDetails = invoiceDraft.invoicedetails.filter(
            (item) => item.child_id == child_id
        );

        if (invoiceDetails.length == 0) {
            return false;
        }

        const rows = invoiceDetails.map((item, index) => {
            // const total =
            //     (parseFloat(item.invd_line_amount) *
            //         parseFloat(item.invd_line_vat)) /
            //     100 +
            //     item.invd_line_amount;

            const total = item.invd_total;

            return (
                <tr key={index}>
                    <td>{item.services_name}</td>
                    <td>{parseFloat(item.invd_line_amount).toFixed(2)}</td>
                    <td>{item.invd_line_qty}</td>
                    <td>{parseFloat(item.invd_line_discount).toFixed(2)}%</td>
                    <td>{parseFloat(item.invd_line_vat).toFixed(2)}</td>
                    <td>{item.invd_minutes}</td>
                    <td>{parseFloat(total).toFixed(2)}</td>
                    <td align='right'>
                        <FeatherIcon
                            onClick={() =>
                                handleDelService(item.invd_id, item.child_id)
                            }
                            style={{
                                cursor: 'pointer',
                                color: '#E66344',
                            }}
                            icon='trash-2'
                            size={15}
                        />
                    </td>
                </tr>
            );
        });

        return (
            <Table className='table-borderless table-sm align-middle table-nowrap mt-2 mb-0'>
                <thead className='table-light'>
                    <tr>
                        <th scope='col'>Services</th>
                        <th scope='col'>Amount</th>
                        <th scope='col'>Quantity</th>
                        <th scope='col'>Discount</th>
                        <th scope='col'>VAT</th>
                        <th scope='col'>Minutes</th>
                        <th scope='col'>Total</th>
                        <th scope='col'></th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        );
    };

    // Children list
    const childrenList = children.map((item, index) => (
        <ListGroupItem className='py-3' key={index}>
            <div className='d-flex align-items-center'>
                <div className='flex-shrink-0'>
                    <Thumbnail moduleName='children' fileName={item.picture} />
                </div>
                <div className='flex-grow-1 ms-2'>
                    <h5 className='fs-14 mb-0'>{item.child_name}</h5>
                    <p className='text-muted mb-0'>{item.child_code}</p>
                </div>
                <Button
                    color='success'
                    className='btn-icon ms-2'
                    title='Clear'
                    onClick={() => {
                        setState({
                            ...state,
                            serviceForm: {
                                ...state.serviceForm,
                                child_id: item.child_id,
                                child_name: item.child_name,
                                child_code: item.child_code,
                            },
                        });
                        toggleOffCanvas();
                    }}>
                    <FeatherIcon icon='plus' size={15} />
                </Button>
            </div>
            <div className='table-responsive'>
                {servicesTable(item.child_id)}
            </div>
        </ListGroupItem>
    ));

    // Handle quantity
    const handleQty = (mins, action) => {
        if (action === null) {
            setState({
                ...state,
                serviceForm: {
                    ...state.serviceForm,
                    services_qty: parseInt(mins),
                },
            });
        } else {
            if (action) {
                setState({
                    ...state,
                    serviceForm: {
                        ...state.serviceForm,
                        services_qty: parseInt(mins + 1),
                    },
                });
            } else {
                if (state.serviceForm.services_qty > 1) {
                    setState({
                        ...state,
                        serviceForm: {
                            ...state.serviceForm,
                            services_qty: parseInt(mins - 1),
                        },
                    });
                }
            }
        }
    };

    // Datatable: columns
    const columns = [
        {
            key: null,
            name: 'Actions',
            selector: (row) => (
                <React.Fragment>
                    <Button
                        title='Edit'
                        onClick={() => editThis(row)}
                        color='secondary'
                        className='btn-icon btn-sm me-1'>
                        <i className='ri-pencil-fill' />
                    </Button>
                    <Link
                        to={{
                            pathname: `/invoice-details`,
                            state: {
                                inv_id: row.inv_id,
                            },
                        }}
                        title='View'
                        className='btn btn-warning btn-sm'
                        style={{ fontSize: 11 }}>
                        <i className='ri-eye-line' />
                    </Link>
                </React.Fragment>
            ),
            sortable: false,
            width: '100px',
        },
        {
            key: 'inv_num',
            name: 'Invoice #',
            selector: (row) => row.inv_num,
            sortable: true,
            omit: state.hideInvNumCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideInvNumCol: !state.hideInvNumCol,
                }),
        },
        {
            key: 'parent_name',
            name: 'Name',
            width: '150px',
            selector: (row) => row.parent_name,
            sortable: true,
            omit: state.hideNameCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideNameCol: !state.hideNameCol,
                }),
        },
        {
            key: 'parent_code',
            name: 'Code',
            width: '150px',
            selector: (row) => row.parent_code,
            sortable: true,
            omit: state.hideCodeCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideCodeCol: !state.hideCodeCol,
                }),
        },
        {
            key: 'inv_amount',
            name: 'Amount',
            selector: (row) => row.inv_amount,
            sortable: true,
            omit: state.hideAmountCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideAmountCol: !state.hideAmountCol,
                }),
        },
        {
            key: 'inv_vat',
            name: 'VAT',
            selector: (row) => row.inv_vat,
            sortable: true,
            omit: state.hideVatCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideVatCol: !state.hideVatCol,
                }),
        },
        {
            key: 'inv_date',
            name: 'Date',
            selector: (row) => moment(row.inv_date).format('DD-MM-YYYY'),
            sortable: true,
            omit: state.hideDateCol,
            hideColFunc: () =>
                setState({
                    ...state,
                    hideDateCol: !state.hideDateCol,
                }),
        },
    ];

    // Datatable: fetch data
    let fetchDataReq;
    const fetchData = async (page, newPerPage = 10) => {
        // setLoading(true);
        setStateDT({ ...stateDT, loading: true });
        if (fetchDataReq) {
            fetchDataReq.abort();
        }
        fetchDataReq = new AbortController();
        let parameters = {
            center_id: user.center_id,
            offset: (page - 1) * newPerPage,
            order: stateDT.sort.field,
            dir: stateDT.sort.dir,
            page: state.page,
            limit: newPerPage,
            search: stateDT.searchKeyword,
            only_active: state.statusAll,
        };

        const res = await tableFetch('invoice/fetch', fetchDataReq, parameters);
        if (res) {
            setStateDT({
                ...stateDT,
                data: res.data,
                currentPage: page,
                totalRows: res.total_count_filtered,
                perPage: newPerPage,
                loading: false,
            });
        } else {
            setStateDT({ ...stateDT, loading: false });
        }
        // setLoading(false);
    };

    // Datatable: sort
    const handleSort = (column, sortDirection) => {
        const field = column.key;
        const dir = sortDirection.toUpperCase();
        setStateDT({ ...stateDT, sort: { field, dir } });
    };

    // Datatable: page change
    const handlePageChange = (page) => {
        fetchData(page, stateDT.perPage);
    };

    // Datatable: header component
    const subHeaderComponent = (
        <React.Fragment>
            <Button
                color='primary'
                className='me-3'
                onClick={() => CSVExport()}>
                CSV
            </Button>
            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light'
                    id='dropdownMenuButton'>
                    Columns Visibility <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {columns
                        .filter((col) => col.key !== null)
                        .map((item, index) => (
                            <DropdownItem
                                key={index}
                                onClick={() => item.hideColFunc()}
                                className={item.omit ? 'text-muted' : ''}>
                                <FeatherIcon
                                    className='me-1'
                                    icon={item.omit ? 'eye-off' : 'eye'}
                                    size={14}
                                />{' '}
                                {item.name}
                            </DropdownItem>
                        ))}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon right'>
                <Input
                    // value={stateDT.searchKeyword}
                    type='text'
                    className='form-control form-control-icon'
                    id='iconleftInput'
                    placeholder='Search here...'
                    onChange={(e) =>
                        setStateDT({ ...stateDT, searchKeyword: e.target.value })
                    }
                />
                {stateDT.searchKeyword != '' ? (
                    <i
                        onClick={() =>
                            setStateDT({ ...stateDT, searchKeyword: '' })
                        }
                        className='mdi mdi-close search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                ) : (
                    <i
                        className='mdi mdi-magnify search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                )}
            </div>
        </React.Fragment>
    );

    // Datatable: user effect
    useEffect(() => {
        fetchData(stateDT.currentPage, stateDT.perPage);
    }, [stateDT.sort, stateDT.searchKeyword]);

    // Datatable: export csv header
    const CSVHeader = () => {
        const filteredCol = columns
            .filter((column) => column.key !== null && !column.omit)
            .map((column) => {
                return { label: column.name, key: column.key };
            });

        return filteredCol;
    };

    // Datatable: export CSV
    const CSVExport = () => {
        const CSVHeaderKeys = CSVHeader().map((header) => header.key);

        const csvExportData = stateDT.data.map((row) => {
            return Object.assign(
                {},
                ...Object.entries({ ...CSVHeaderKeys }).map(
                    ([item, index]) => ({
                        [index]: row[index],
                    })
                )
            );
        });

        // setCSVData(csvExportData);
        setStateDT({ ...stateDT, CSVData: csvExportData });

        setTimeout(() => {
            CSVLinkEl.current.link.click();
        });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <CSVLink
                headers={CSVHeader()}
                filename='services_export.csv'
                data={stateDT.CSVData}
                ref={CSVLinkEl}
            />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    {translate('Invoices')}
                                </h4>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className='g-3'>
                                <Col md={6}>
                                    <Form
                                        id='data-form'
                                        onSubmit={(e) => searchChildren(e)}>
                                        <div className='input-group'>
                                            <input
                                                value={searchChildrenKeyword}
                                                type='text'
                                                id='iconleftInput'
                                                placeholder='Search with child or parent code'
                                                onChange={(e) =>
                                                    setSearchChildrenKeyword(
                                                        e.target.value
                                                    )
                                                }
                                                className='form-control'
                                            />
                                            <button
                                                disabled={disableSearch}
                                                type='submit'
                                                className='btn btn-danger'>
                                                SEARCH
                                            </button>
                                        </div>
                                    </Form>
                                </Col>
                                <Col md={1}>
                                    {state.searching && (
                                        <Spinner
                                            style={{ marginTop: 10 }}
                                            size='sm'
                                            color='danger'
                                            className='flex-shrink-0'></Spinner>
                                    )}
                                </Col>
                                <Col md={5} className='text-end'>
                                    {/*form_processing && (
                                        <Spinner
                                            style={{ marginRight: 15 }}
                                            size='sm'
                                            color='success'
                                            className='flex-shrink-0'></Spinner>
                                    )*/}
                                    {isEditing ? (
                                        <>
                                            {invoiceDraft.inv_paid == 0 && (
                                                <Button
                                                    onClick={() =>
                                                        handleSubmit('save')
                                                    }
                                                    disabled={
                                                        invoiceDraft
                                                            .invoicedetails
                                                            .length == 0 && true
                                                    }
                                                    color='danger'
                                                    className='btn-label'>
                                                    <i className='ri-file-text-line label-icon align-middle fs-16 me-2'></i>
                                                    {translate(
                                                        'CREDIT INVOICE'
                                                    )}
                                                </Button>
                                            )}
                                            {invoiceDraft.inv_paid == 1 && (
                                                <Button
                                                    onClick={() =>
                                                        handleSubmit('pay&save')
                                                    }
                                                    disabled={
                                                        invoiceDraft
                                                            .invoicedetails
                                                            .length == 0 && true
                                                    }
                                                    color='success'
                                                    className='btn-label ms-3'>
                                                    <i className='ri-file-text-line label-icon align-middle fs-16 me-2'></i>
                                                    {translate('PAID INVOICE')}
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <React.Fragment>
                                            <Button
                                                onClick={() =>
                                                    handleSubmit('save')
                                                }
                                                disabled={
                                                    invoiceDraft.invoicedetails
                                                        .length == 0 && true
                                                }
                                                color='danger'
                                                className='btn-label'>
                                                <i className='ri-file-text-line label-icon align-middle fs-16 me-2'></i>
                                                {translate('CREDIT INVOICE')}
                                            </Button>
                                            <Button
                                                onClick={() =>
                                                    handleSubmit('pay&save')
                                                }
                                                disabled={
                                                    invoiceDraft.invoicedetails
                                                        .length == 0 && true
                                                }
                                                color='success'
                                                className='btn-label ms-3'>
                                                <i className='ri-file-text-line label-icon align-middle fs-16 me-2'></i>
                                                {translate('PAID INVOICE')}
                                            </Button>
                                        </React.Fragment>
                                    )}
                                    <Button
                                        onClick={() => cancel()}
                                        color='primary'
                                        disabled={children.length == 0 && true}
                                        className='ms-3'>
                                        {translate('CANCEL')}
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                {children.length > 0 && (
                                    <Col md={12} className='pt-3'>
                                        <React.Fragment>
                                            <h4>
                                                {parent.parent_name}'s Children
                                            </h4>
                                            <ListGroup>
                                                {childrenList}
                                            </ListGroup>
                                        </React.Fragment>
                                    </Col>
                                )}
                            </Row>
                        </CardBody>
                    </Card>
                    <Card>
                        <CardBody>
                            <DataTable
                                columns={columns}
                                data={stateDT.data}
                                progressPending={stateDT.loading}
                                pagination
                                paginationServer
                                paginationTotalRows={stateDT.totalRows}
                                onChangePage={handlePageChange}
                                onChangeRowsPerPage={(newPerPage, page) =>
                                    fetchData(page, newPerPage)
                                }
                                subHeader
                                subHeaderComponent={subHeaderComponent}
                                onSort={handleSort}
                                sortServer
                                theme={
                                    layoutModeType == 'dark'
                                        ? 'dark'
                                        : 'default'
                                }
                            />
                        </CardBody>
                    </Card>
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={offCanvasOpen}
                    toggle={resetForm}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={resetForm}>
                        <span className='m-0 me-2'>
                            {services_id
                                ? translate('Update Service')
                                : translate('Add Service')}
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            id='data-form'
                            onSubmit={handleAddService}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='form-label'>
                                                {translate('Child Name')}
                                            </Label>
                                            <div
                                                className='text-muted'
                                                style={{
                                                    lineHeight: '37px',
                                                    borderBottomColor:
                                                        '#CFD4DA',
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: '1px',
                                                }}>
                                                {state.serviceForm.child_name}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='form-label'>
                                                {translate('Child Code')}
                                            </Label>
                                            <div
                                                className='text-muted'
                                                style={{
                                                    lineHeight: '37px',
                                                    borderBottomColor:
                                                        '#CFD4DA',
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: '1px',
                                                }}>
                                                {state.serviceForm.child_code}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='services_type'>
                                                {translate('Service')}
                                            </Label>
                                            <Select
                                                className={
                                                    formHasError && 'is-invalid'
                                                }
                                                styles={selectFldStyle}
                                                name='Services[services_type]'
                                                onChange={(e) => {
                                                    handleService(e);
                                                }}
                                                options={servicesDDL}
                                                invalid={true}
                                                id='services_type'
                                            />
                                            <FormFeedback>
                                                {translate(
                                                    'Please select service.'
                                                )}
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                    <Col sm={6}>
                                        <div>
                                            <Label className='form-label'>
                                                {translate('Quantity')}
                                            </Label>
                                            <br />
                                            <div className='input-step'>
                                                <button
                                                    type='button'
                                                    className='minus'
                                                    onClick={() =>
                                                        handleQty(
                                                            state.serviceForm
                                                                .services_qty,
                                                            false
                                                        )
                                                    }>
                                                    –
                                                </button>
                                                <Input
                                                    name='Services[services_minutes]'
                                                    type='number'
                                                    className='product-quantity'
                                                    value={
                                                        state.serviceForm
                                                            .services_qty
                                                    }
                                                    style={{ width: '100%' }}
                                                    min={1}
                                                    onChange={(e) =>
                                                        justNums(
                                                            e.target.value
                                                        ) &&
                                                        handleQty(
                                                            e.target.value,
                                                            null
                                                        )
                                                    }
                                                />
                                                <button
                                                    type='button'
                                                    className='plus'
                                                    onClick={() =>
                                                        handleQty(
                                                            state.serviceForm
                                                                .services_qty,
                                                            true
                                                        )
                                                    }>
                                                    +
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='form-label'>
                                                {translate('Service Fee')}
                                            </Label>
                                            <div
                                                className='text-muted'
                                                style={{
                                                    lineHeight: '37px',
                                                    borderBottomColor:
                                                        '#CFD4DA',
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: '1px',
                                                }}>
                                                {(
                                                    parseFloat(
                                                        state.serviceForm
                                                            .services_fee
                                                    ) *
                                                    state.serviceForm
                                                        .services_qty
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='form-label'>
                                                {translate('Service Minutes')}
                                            </Label>
                                            <div
                                                className='text-muted'
                                                style={{
                                                    lineHeight: '37px',
                                                    borderBottomColor:
                                                        '#CFD4DA',
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: '1px',
                                                }}>
                                                {parseInt(
                                                    state.serviceForm
                                                        .services_minutes
                                                ) *
                                                    state.serviceForm
                                                        .services_qty}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='invd_line_discount'>
                                                {translate('Discount')}
                                            </Label>
                                            <div class='form-icon right'>
                                                <Input
                                                    className='form-control-icon'
                                                    name='Services[invd_line_discount]'
                                                    type='text'
                                                    maxlength='2'
                                                    value={
                                                        state.serviceForm
                                                            .services_disc
                                                    }
                                                    style={{ width: '100%' }}
                                                    onChange={(e) =>
                                                        justNums(
                                                            e.target.value
                                                        ) &&
                                                        setState({
                                                            ...state,
                                                            serviceForm: {
                                                                ...state.serviceForm,
                                                                services_disc:
                                                                    e.target
                                                                        .value,
                                                            },
                                                        })
                                                    }
                                                />
                                                <i class='ri-percent-line'></i>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className='mb-3'>
                                            <Label className='form-label'>
                                                {translate('Total')}
                                            </Label>
                                            <div
                                                className='text-muted'
                                                style={{
                                                    lineHeight: '37px',
                                                    borderBottomColor:
                                                        '#CFD4DA',
                                                    borderBottomStyle: 'solid',
                                                    borderBottomWidth: '1px',
                                                }}>
                                                {(
                                                    parseFloat(
                                                        state.serviceForm
                                                            .services_fee
                                                    ) *
                                                    state.serviceForm
                                                        .services_qty -
                                                    (parseFloat(
                                                        state.serviceForm
                                                            .services_fee
                                                    ) *
                                                        state.serviceForm
                                                            .services_qty *
                                                        state.serviceForm
                                                            .services_disc) /
                                                    100
                                                ).toFixed(2)}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                disabled={form_processing}
                                                color={
                                                    services_id
                                                        ? 'warning'
                                                        : 'success'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    <span className='flex-grow-1'>
                                                        {translate(
                                                            'Add Service'
                                                        )}
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
        </React.Fragment>
    );
};

export default Invoices;
