export const setDefaultLanguageDispatch = (payload) => {
    return {
        type: 'DEFAULT_LANGUAGE',
        payload: payload
    }
}
export const FetchLanguages = (payload) => {
    return {
        type: 'LOADING_LANGUAGES_DATA',
        payload: payload
    }
}
export const FetchLanguagesDispatch = (payload) => {
    return {
        type: 'LANGUAGES',
        payload: payload
    }
}



export const FetchLanguageLabels = (model_name, filetype) => {
    return {
        type: 'LOADING_LABELS_DATA',
        payload: { model_name, filetype }
    }
}

export const FetchLabelLanguagesDispatch = (payload) => {
    return {
        type: 'LABEL_LANGUAGES',
        payload: payload
    }
}
// export const FetchLanguages = () => {
//     console.log('here1');
//     try {
//         return dispatch => {
//             console.log('here');
//             dispatch(LoadingLanguagesDataDispatch(true))
//             axios.get(process.env.REACT_APP_API_URL + '/language/get-languages')
//                 .then(function (response) {
//                     console.log('SFetchLanguagesresponse', response);
//                     let resData = response.data
//                     dispatch(FetchLanguagesDispatch(resData.data))
//                     dispatch(LoadingLanguagesDataDispatch(false))
//                 })
//                 .catch(function (error) {
//                     console.log('EFetchLanguagesresponse', error);
//                     dispatch(LoadingLanguagesDataDispatch(false))
//                 })
//         }
//     } catch (error) {
//         console.log('here2', error);

//     }

// }



// export const FetchLabelLanguages = (model_name, filetype, offset, data) => {
//     return dispatch => {
//         if (offset == undefined) {
//             offset = 0
//             data = []
//             dispatch(LoadingLabelsDataDispatch(true))
//         }
//         FetchLabelLanguagesAxios(model_name, filetype, offset, data, dispatch)
//     }
// }

// export const FetchLabelLanguagesAxios = (model_name, filetype, offset, data, dispatch) => {

//     axios.get(process.env.REACT_APP_API_URL + '/language/get-language-labels', {
//         params: {
//             offset: offset,
//             filetype: filetype,
//             model_name: model_name,
//         }
//     })
//         .then(function (response) {
//             let resData = response.data
//             if (resData.data.length > 0) {
//                 resData.data.map((v, i) => {
//                     data.push(v)
//                 })
//                 FetchLabelLanguagesAxios(model_name, filetype, (offset + 100), data, dispatch)
//             } else {
//                 dispatch(FetchLabelLanguagesDispatch(data))
//                 dispatch(LoadingLabelsDataDispatch(false))
//             }
//         })
//         .catch(function (error) {
//             dispatch(LoadingLabelsDataDispatch(false))
//         })
// }

