import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import Widget from './Widgets';
import BestSellingProducts from './BestSellingProducts';
import RecentActivity from './RecentActivity';
import RecentOrders from './RecentOrders';
import Revenue from './Revenue';
import SalesByLocations from './SalesByLocations';
import Section from './Section';
import StoreVisits from './StoreVisits';
import TopSellers from './TopSellers';
import { useSelector } from 'react-redux';
import { setDefaultLanguageDispatch } from '../../store/actions';

const DashboardEcommerce = () => {
    document.title = 'Dashboard | Kinderiffic';

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <BreadCrumb title='Dashboard' pageTitle='Dashboards' />
                    <Row>
                        <Col>
                            <div className='h-100'>
                                <Section />
                                <Row>
                                    <Widget />
                                </Row>
                                <Row>
                                    <Col xl={8}>
                                        <Revenue />
                                    </Col>
                                    <SalesByLocations />
                                </Row>
                                <Row>
                                    <BestSellingProducts />
                                    <TopSellers />
                                </Row>
                                <Row>
                                    <StoreVisits />
                                    <RecentOrders />
                                </Row>
                            </div>
                        </Col>
                        <RecentActivity />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default DashboardEcommerce;
