import React from 'react';
import { withRouter } from 'react-router-dom';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

const ParticlesAuth = ({ children }) => {
    const particlesInit = async (main) => {
        console.log('particlesInit', main);

        // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(main);
    };

    const particlesLoaded = (container) => {
        console.log('particlesLoaded', container);
    };
    return (
        <React.Fragment>
            <div className='auth-page-wrapper pt-5'>
                <div
                    className='auth-one-bg-position auth-one-bg'
                    id='auth-particles'>
                    <div className='bg-overlay'></div>

                    <div className='shape'>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            version='1.1'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            viewBox='0 0 1440 120'>
                            <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                        </svg>
                    </div>
                    <Particles
                        id='tsparticles'
                        init={particlesInit}
                        loaded={particlesLoaded}
                        options={{
                            fullScreen: {
                                enable: false,
                                zIndex: 0,
                            },
                            particles: {
                                number: {
                                    value: 400,
                                    density: {
                                        enable: true,
                                        value_area: 800,
                                    },
                                },
                                color: {
                                    value: '#fff',
                                },
                                shape: {
                                    type: 'circle',
                                },
                                opacity: {
                                    value: 0.5,
                                    random: true,
                                    anim: {
                                        enable: false,
                                        speed: 1,
                                        opacity_min: 0.1,
                                        sync: false,
                                    },
                                },
                                size: {
                                    value: 10,
                                    random: true,
                                    anim: {
                                        enable: false,
                                        speed: 40,
                                        size_min: 0.1,
                                        sync: false,
                                    },
                                },
                                line_linked: {
                                    enable: false,
                                    distance: 500,
                                    color: '#ffffff',
                                    opacity: 0.4,
                                    width: 2,
                                },
                                move: {
                                    enable: true,
                                    speed: 2,
                                    direction: 'bottom',
                                    random: false,
                                    straight: false,
                                    out_mode: 'out',
                                    attract: {
                                        enable: false,
                                        rotateX: 600,
                                        rotateY: 1200,
                                    },
                                },
                            },
                            interactivity: {
                                events: {
                                    onhover: {
                                        enable: true,
                                        mode: 'bubble',
                                    },
                                    onclick: {
                                        enable: true,
                                        mode: 'repulse',
                                    },
                                    resize: true,
                                },
                                modes: {
                                    grab: {
                                        distance: 400,
                                        line_linked: {
                                            opacity: 0.5,
                                        },
                                    },
                                    bubble: {
                                        distance: 400,
                                        size: 4,
                                        duration: 0.3,
                                        opacity: 1,
                                        speed: 3,
                                    },
                                    repulse: {
                                        distance: 200,
                                    },
                                    push: {
                                        particles_nb: 4,
                                    },
                                    remove: {
                                        particles_nb: 2,
                                    },
                                },
                            },
                            retina_detect: true,
                            config_demo: {
                                hide_card: false,
                                background_color: '#b61924',
                                background_image: '',
                                background_position: '50% 50%',
                                background_repeat: 'no-repeat',
                                background_size: 'cover',
                            },
                        }}
                    />
                </div>

                {/* pass the children */}
                {children}

                <footer className='footer'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='text-center'>
                                    <p className='mb-0 text-muted'>
                                        &copy; {new Date().getFullYear()}{' '}
                                        Kinderiffic. Made with{' '}
                                        <i className='mdi mdi-heart text-danger'></i>{' '}
                                        by{' '}
                                        <strong>
                                            <a
                                                href='https://www.educoresystem.com/'
                                                target={'_blank'}>
                                                Educore Systems and Technologies
                                            </a>
                                        </strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);
