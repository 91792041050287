import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Form, FormFeedback, Label, Input, Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, ButtonGroup } from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { justNums, handleFormError, showToast, getCountries, getDDLOptions, } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import {
    axiosPOST,
    CSVHeader,
    PDFExport,
    tableFetch,
    CSVExport,
    dtColumnConfig,
    axiosGET,
} from '../../helpers/datatable_helper';
import Swal from 'sweetalert2';
import moment from 'moment';
import { translate } from '../../helpers/language_helper';
import { FilePond, registerPlugin } from 'react-filepond';


const Center = () => {
    document.title = 'Center | Kinderiffic';
    const offCanvasWidth = '35%';

    // States-E-Module
    const { USER, CENTER, layoutModeType } = useSelector((state) => ({
        USER: state.User,
        CENTER: state.Center,
        layoutModeType: state.Layout.layoutModeType
    }));

    const [state, setState] = useState({
        form_processing: false,
        loading: false,
        validationErrors: '',
        center_name: '',
        center_desc: '',
        contact_title: '',
        contact_name: '',
        phone1: '',
        phone2: '',
        email1: '',
        email2: '',
        center_timezone: '',
        address: '',
        country_id: '',
        logo: '',
        urlwww: '',
        urlinstagram: '',
        urlfacebook: '',
        urltwitter: '',
        urllinkedin: '',
        urlcloud: '',
        urlyoutube: '',
        center_contract_startdate: '',
        center_contract_expiry_date: '',
        latitude: '',
        longitude: '',
        currencycode: '',
        establishment_num: '',
        bank_code: '',
        bank_details: '',
        vat_num: '',
        primary_center_id: '',
        payment_gateway_url: '',
        weekend_days: '',
        zone_id: '',
        allowed_capacity: '',
        default_language: '',
        default_app_color: '',
        vimeo_project_uri: '',
        center_id: ''
    });
    const [attachment, set_attachment] = useState([]);
    const [countries, set_countries] = useState([]);


    const nameTitles = [
        { value: 'Mr', label: 'Mr' },
        { value: 'Mrs', label: 'Mrs' },
        { value: 'Miss', label: 'Miss' },
        { value: 'Ms', label: 'Ms' },
    ];

    //START FOR CALLBACK STATES
    const myCallbacksList = useRef([]);
    const setStateWCall = (newState, callback) => {
        setState(newState);
        if (callback) myCallbacksList.current.push(callback);
    };
    useEffect(() => {
        myCallbacksList.current.forEach((callback) => callback(state));
        myCallbacksList.current = [];
    }, [state]);
    //END FOR CALLBACK STATES

    useEffect(() => {
        async function componentDidMount() {
            let getCountriesData = await getCountries()
            set_countries(getDDLOptions(getCountriesData, 'country_id', 'name_en'))
            let res = await axiosGET('center/fetch', { center_id: CENTER.center_id })
            console.log('res', res);
            let countryFind = getCountriesData.find((v, i) => v.country_id == res.country_id)
            console.log('countryFind', countryFind);
            setState({
                ...state, center_name: res.center_name, center_desc: res.center_desc, contact_title: { value: res.contact_title, label: res.contact_title }, contact_name: res.contact_name, phone1: res.phone1, phone2: res.phone2, email1: res.email1, email2: res.email2, center_timezone: res.center_timezone, address: res.address, country_id: { value: countryFind['country_id'], label: countryFind['name_en'] }, logo: res.logo, urlwww: res.urlwww, urlinstagram: res.urlinstagram, urlfacebook: res.urlfacebook, urltwitter: res.urltwitter, urllinkedin: res.urllinkedin, urlcloud: res.urlcloud, urlyoutube: res.urlyoutube, center_contract_startdate: res.center_contract_startdate, center_contract_expiry_date: res.center_contract_expiry_date, latitude: res.latitude, longitude: res.longitude, currencycode: res.currencycode, establishment_num: res.establishment_num, bank_code: res.bank_code, bank_details: res.bank_details, vat_num: res.vat_num, primary_center_id: res.primary_center_id, payment_gateway_url: res.payment_gateway_url, weekend_days: res.weekend_days, zone_id: res.zone_id, allowed_capacity: res.allowed_capacity, default_language: res.default_language, default_app_color: res.default_app_color, vimeo_project_uri: res.vimeo_project_uri, center_id: res.center_id
            })
            const path = res.center_id + '/centers/' + res['logo'];
            if (res['logo'] != null && res['logo'] != false) {
                set_attachment([
                    {
                        source: path,
                        options: {
                            type: "local"//local=>MEANS ALREADY UPLOADED https://pqina.nl/filepond/docs/api/instance/properties/#files
                        }
                    }
                ])
            }
        }
        componentDidMount()
    }, []);

    // Handle submit
    let handleSubmitReq;
    const handleSubmit = async (e) => {
        e.preventDefault();
        // set_form_processing(true);
        setStateWCall({ ...state, form_processing: true }, async (state) => {
            const dataForm = document.getElementById('data-form');
            const fData = new FormData(dataForm);
            if (attachment.length > 0 && typeof attachment[0].source !== 'string') {
                fData.append('logo', attachment[0].file);
            }
            fData.append('center_timezone', CENTER.center_timezone)
            fData.append('center_id', CENTER.center_id)
            fData.append('user_id', USER.user_id)

            if (handleSubmitReq) {
                handleSubmitReq.abort();
            }
            handleSubmitReq = new AbortController();
            const res = await axiosPOST('center/update', fData, {}, handleSubmitReq);
            console.log('center/update', res);
            if (res) {
                if (res.type) {
                    setState({ ...state, validationErrors: null, form_processing: false });
                } else {
                    setState({ ...state, validationErrors: res.data, form_processing: false });
                }
                showToast(res.message, res.messageType);
            }
        });
    };



    // Reset form
    const resetForm = (offCanvasOpen) => {
        setState({
            ...state,
            services_id: 0,
            services_name: '',
            services_desc: '',
            services_fee: '',
            services_cost: '',
            services_vat: '',
            tax_exempt: 0,
            services_status: 1,
            services_type: null,
            validationErrors: null,
            form_processing: false,
            offCanvasOpen: offCanvasOpen,
        });
    };

    // Edit
    const editThis = (row) => {
        const formFields = [
            'services_id',
            'services_name',
            'services_fee',
            'services_cost',
            'services_type',
            'services_minutes',
            'services_vat',
            'tax_exempt',
            'services_desc',
            'services_status',
        ];
        let newState = { ...state };
        formFields.map((item, index) => {
            if ('services_type' === item) {
                newState = {
                    ...newState,
                    [item]: {
                        value: row[item],
                        label: row[item],
                    },
                };
            } else if ('tax_exempt' === item || 'services_status' === item) {
                newState = { ...newState, [item]: parseInt(row[item]) };
            } else {
                newState = { ...newState, [item]: row[item] };
            }
        });
        setState({ ...newState, offCanvasOpen: true });
    };

    console.log('state', state);
    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>{translate('Center')}</h4>
                            </div>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <Form
                                id='data-form'
                                onSubmit={handleSubmit}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                }}>
                                <div style={{ flex: 1 }}>
                                    <Row>
                                        <Col md={8}>
                                            <Row className='mb-2'>
                                                {/* center_name */}
                                                <Col md={5}>
                                                    <Label htmlFor='center_name' className='form-label'> {translate('Center Name')} </Label>
                                                    <Input name='Centers[center_name]' value={state.center_name} onChange={(e) => setState({ ...state, center_name: e.target.value })} type='text' className='form-control' placeholder='' id='center_name' invalid={handleFormError('center_name', state.validationErrors).show} />
                                                </Col>
                                                {/* contact_title */}
                                                <Col md={2}>
                                                    <Label htmlFor='contact_title' className='form-label'> {translate('Title')} </Label>
                                                    <Select className={handleFormError('contact_title', state.validationErrors).show && 'is-invalid'} styles={{ control: () => ({ borderColor: handleFormError('contact_title', state.validationErrors).show ? '#f06548' : '#CFD4DA', borderWidth: 1, borderStyle: 'solid', display: 'flex', borderRadius: 5, }) }} name='Centers[contact_title]' defaultValue={[state.contact_title]} value={state.contact_title} onChange={(value) => setState({ ...state, contact_title: value })} options={nameTitles} invalid={true} id='contact_title'
                                                    />
                                                </Col>
                                                {/* contact_name */}
                                                <Col md={5}>
                                                    <Label htmlFor='contact_name' className='form-label'> {translate('Name')} </Label>
                                                    <Input name='Centers[contact_name]' value={state.contact_name} onChange={(e) => setState({ ...state, contact_name: e.target.value })} type='text' className='form-control' placeholder='' id='contact_name' invalid={handleFormError('contact_name', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col md={3}>
                                                    <Label htmlFor='phone1' className='form-label'> {translate('Phone')} </Label>
                                                    <Input name='Centers[phone1]' value={state.phone1} onChange={(e) => setState({ ...state, phone1: e.target.value })} type='text' className='form-control' placeholder='' id='phone1' invalid={handleFormError('phone1', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='email1' className='form-label'> {translate('Email')} </Label>
                                                    <Input name='Centers[email1]' value={state.email1} onChange={(e) => setState({ ...state, email1: e.target.value })} type='text' className='form-control' placeholder='' id='email1' invalid={handleFormError('email1', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='phone2' className='form-label'> {translate('Phone 2')} </Label>
                                                    <Input name='Centers[phone2]' value={state.phone2} onChange={(e) => setState({ ...state, phone2: e.target.value })} type='text' className='form-control' placeholder='' id='phone2' invalid={handleFormError('phone2', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='email2' className='form-label'> {translate('Email 2')} </Label>
                                                    <Input name='Centers[email2]' value={state.email2} onChange={(e) => setState({ ...state, email2: e.target.value })} type='text' className='form-control' placeholder='' id='email2' invalid={handleFormError('email2', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col md={6}>
                                                    <Label htmlFor='center_desc' className='form-label'> {translate('About Center')} </Label>
                                                    <Input name='Centers[center_desc]' value={state.center_desc} onChange={(e) => setState({ ...state, center_desc: e.target.value })} type='text' className='form-control' placeholder='' id='center_desc' invalid={handleFormError('center_desc', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='center_timezone' className='form-label'> {translate('Time Zone')} </Label>
                                                    <Input name='Centers[center_timezone]' value={state.center_timezone} onChange={(e) => setState({ ...state, center_timezone: e.target.value })} type='text' className='form-control' placeholder='' id='center_timezone' invalid={handleFormError('center_timezone', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='address' className='form-label'> {translate('Address')} </Label>
                                                    <Input name='Centers[address]' value={state.address} onChange={(e) => setState({ ...state, address: e.target.value })} type='text' className='form-control' placeholder='' id='address' invalid={handleFormError('address', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col md={6}>
                                                    <Label htmlFor='country_id' className='form-label'> {translate('Country')} </Label>
                                                    <Select className={handleFormError('country_id', state.validationErrors).show && 'is-invalid'} styles={{ control: () => ({ borderColor: handleFormError('employee_gender', state.validationErrors).show ? '#f06548' : '#CFD4DA', borderWidth: 1, borderStyle: 'solid', display: 'flex', borderRadius: 5, }) }} name='Centers[country_id]' value={state.country_id} onChange={(value) => setState({ ...state, country_id: value })} options={countries} invalid={true} id='country_id'
                                                    />
                                                </Col>
                                                <Col md={6}>
                                                    <Label htmlFor='vat_num' className='form-label'> {translate('VAT Number')} </Label>
                                                    <Input name='Centers[vat_num]' value={state.vat_num} onChange={(e) => setState({ ...state, vat_num: e.target.value })} type='text' className='form-control' placeholder='' id='vat_num' invalid={handleFormError('vat_num', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col md={3}>
                                                    <Label htmlFor='urlwww' className='form-label'> {translate('Website')} </Label>
                                                    <Input name='Centers[urlwww]' value={state.urlwww} onChange={(e) => setState({ ...state, urlwww: e.target.value })} type='text' className='form-control' placeholder='' id='urlwww' invalid={handleFormError('urlwww', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='urlinstagram' className='form-label'> {translate('Instagram')} </Label>
                                                    <Input name='Centers[urlinstagram]' value={state.urlinstagram} onChange={(e) => setState({ ...state, urlinstagram: e.target.value })} type='text' className='form-control' placeholder='' id='urlinstagram' invalid={handleFormError('urlinstagram', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='urlfacebook' className='form-label'> {translate('Facebook')} </Label>
                                                    <Input name='Centers[urlfacebook]' value={state.urlfacebook} onChange={(e) => setState({ ...state, urlfacebook: e.target.value })} type='text' className='form-control' placeholder='' id='urlfacebook' invalid={handleFormError('urlfacebook', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                            <Row className='mb-2'>
                                                <Col md={3}>
                                                    <Label htmlFor='urltwitter' className='form-label'> {translate('Twitter')} </Label>
                                                    <Input name='Centers[urltwitter]' value={state.urltwitter} onChange={(e) => setState({ ...state, urltwitter: e.target.value })} type='text' className='form-control' placeholder='' id='urltwitter' invalid={handleFormError('urltwitter', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='urllinkedin' className='form-label'> {translate('LinkedIn')} </Label>
                                                    <Input name='Centers[urllinkedin]' value={state.urllinkedin} onChange={(e) => setState({ ...state, urllinkedin: e.target.value })} type='text' className='form-control' placeholder='' id='urllinkedin' invalid={handleFormError('urllinkedin', state.validationErrors).show} />
                                                </Col>
                                                <Col md={3}>
                                                    <Label htmlFor='urlyoutube' className='form-label'> {translate('Youtube')} </Label>
                                                    <Input name='Centers[urlyoutube]' value={state.urlyoutube} onChange={(e) => setState({ ...state, urlyoutube: e.target.value })} type='text' className='form-control' placeholder='' id='urlyoutube' invalid={handleFormError('urlyoutube', state.validationErrors).show} />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col md={4}>
                                            {/* IMAGE PICKER */}
                                            <div className='mb-3'>
                                                <Label className='employee_picture'>
                                                    Picture
                                                </Label>
                                                <FilePond
                                                    server={{
                                                        url: process.env.REACT_APP_API_URL,
                                                        load: '/center/load-attachment?auth_key=' + USER.auth_key + '&file=',
                                                    }}
                                                    instantUpload={false}
                                                    allowProcess={false}
                                                    maxFiles={1}

                                                    beforeRemoveFile={(item) => axiosGET('center/delete-attachment', { center_id: state.center_id, key: state.center_id, field: 'logo' })}
                                                    files={attachment}
                                                    onupdatefiles={set_attachment}
                                                    allowMultiple={false}
                                                    name='logo'
                                                    className='filepond filepond-input'
                                                    labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                                />
                                                <FormFeedback>{handleFormError('employee_picture', state.validationErrors).msg}</FormFeedback>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                <div>
                                    <Row>
                                        <Col md={12}>
                                            <div className='text-end mb-4'>
                                                <Button
                                                    type='submit'
                                                    disabled={state.form_processing}
                                                    color={
                                                        state.center_id
                                                            ? 'warning'
                                                            : 'success'
                                                    }
                                                    className='btn-load'>
                                                    <span className='d-flex align-items-center'>
                                                        {state.form_processing && (
                                                            <Spinner
                                                                size='sm'
                                                                className='flex-shrink-0 me-2'></Spinner>
                                                        )}
                                                        <span className='flex-grow-1'>
                                                            {translate('Update Center')}
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Container>

            </div>
        </React.Fragment>
    );
};

export default Center;
