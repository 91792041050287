import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link, useParams } from 'react-router-dom';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import logoLight from '../../assets/images/logo-light.png';
import { axiosPOST } from '../../helpers/datatable_helper';
import { showToast } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';

const VerifyEmail = (props) => {

    let { user_name } = useParams();

    const [state, setState] = useState({
        code: '',
        verifiedSuccess: false
    })


    const verifyCode = async () => {
        let fData = new FormData()
        fData.append('user_name', user_name)
        fData.append('code', state.code)
        const res = await axiosPOST('login/verify-code', fData, { auth_key: 'verify-code' });
        console.log('verifyCode', res);
        if (res) {
            if (res.type) {
                setState({ ...state, verifiedSuccess: true })
            }
            showToast(res.message, res.messageType);
        }
    }




    document.title = 'Kinderiffic';
    return (
        <React.Fragment>
            <ToastContainer />
            <ParticlesAuth>
                <div className='auth-page-content'>
                    {state.verifiedSuccess
                        ? <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className='text-center mt-sm-5 mb-4 text-white-50'>
                                        <div>
                                            <Link
                                                to='/'
                                                className='d-inline-block auth-logo'>
                                                <img
                                                    src={logoLight}
                                                    alt=''
                                                    height='80'
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col md={8} lg={6} xl={5}>
                                    <Card className='mt-4'>
                                        <CardBody className='p-4'>
                                            <div className='text-center mt-2'>
                                                <h4 className='text-success'>Verified Successfully!</h4>
                                                <p className='text-danger'>Please close and open the application again</p>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                        : <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className='text-center mt-sm-5 mb-4 text-white-50'>
                                        <div>
                                            <Link
                                                to='/'
                                                className='d-inline-block auth-logo'>
                                                <img
                                                    src={logoLight}
                                                    alt=''
                                                    height='80'
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='justify-content-center'>
                                <Col md={8} lg={6} xl={5}>
                                    <Card className='mt-4'>
                                        <CardBody className='p-4'>
                                            <div className='text-center mt-2'>
                                                <h5 className='text-primary'>
                                                    Welcome!
                                                </h5>
                                                <p className='text-muted mb-0'>
                                                    Please enter the Code you received in the
                                                </p>
                                                <p className='text-muted'>
                                                    <strong>Email : {user_name}</strong>
                                                </p>
                                            </div>
                                            <div className='p-2 mt-4'>

                                                <div className='mb-3'>
                                                    <Label
                                                        htmlFor='username'
                                                        className='form-label'>
                                                        Code
                                                    </Label>
                                                    <Input
                                                        name='username'
                                                        className='form-control'
                                                        placeholder='Enter Code'
                                                        type='text'
                                                        onChange={(e) => {
                                                            setState({ ...state, code: e.target.value })
                                                        }}
                                                        value={state.code}
                                                    />
                                                </div>
                                                <div className='mt-4'>
                                                    <Button
                                                        color='success'
                                                        className='btn btn-success w-100'
                                                        type='button'
                                                        onClick={() => verifyCode()}>
                                                        Verify
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(VerifyEmail);
