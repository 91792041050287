import React, { useEffect, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormFeedback,
    Label,
    Input,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Button,
    Card,
    CardBody,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Spinner,
    Badge,
    ButtonGroup,
} from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { handleFormError, showToast } from '../../helpers/common_helper';
import { axiosPOST } from '../../helpers/datatable_helper';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FeatherIcon from 'feather-icons-react';
import moment from 'moment';
import Masonry from 'react-masonry-css';
import './masonry.css';
import Flatpickr from 'react-flatpickr';
import { translate } from '../../helpers/language_helper';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

// Filepond
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

// Register the plugins
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateType
);

const UserTimeline = () => {
    document.title = 'User Timeline | Kinderiffic';
    const offCanvasWidth = '50%';

    // States-E-Module
    const { user, center } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
    }));
    const [offCanvasOpen, setOffCanvasOpen] = useState(false);

    const basePath = `${process.env.REACT_APP_API_URL}/web/attachments/centers/${user.center_id}/user_timeline/`;
    const basePathMedia = `${process.env.REACT_APP_API_URL}/web/attachments/centers/${user.center_id}/user_timeline_media/`;

    // Filter bar
    const typeOptions = [
        {
            ut_type: 'Notification',
            label: 'Notification',
            value: 'Notification',
            active: 1,
        },
        {
            ut_type: 'Newsletter',
            label: 'Newsletter',
            value: 'Newsletter',
            active: 1,
        },
        { ut_type: 'Event', label: 'Event', value: 'Event', active: 1 },
        { ut_type: 'Media', label: 'Media', value: 'Media', active: 1 },
        // { ut_type: 'Poll', label: 'Poll', value: 'Poll', active: 1 },
        // { ut_type: 'Survey', label: 'Survey', value: 'Survey', active: 1 },
        { ut_type: 'Video', label: 'Video', value: 'Video', active: 1 },
    ];
    const [selectedTypes, setSelectedTypes] = useState(typeOptions);
    const [dateRange, setDateRange] = useState([
        moment().toDate(),
        moment().toDate(),
    ]);
    const [filtering, setFiltering] = useState(false); // 🚩
    const [postsSearchKeyword, setPostsSearchKeyword] = useState('');

    // Timeline posts
    const [posts, setPosts] = useState([]);

    // States-E-Form
    const [form_processing, set_form_processing] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});

    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [images, setImages] = useState([]);

    // Use effect
    useEffect(() => {
        fetchUserTimeline();
    }, []);

    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 2,
        500: 1,
    };

    // UT post draft
    const [postDraft, setPostDraft] = useState({
        center_id: user.center_id,
        center_timezone: center.center_timezone,
        user_id: user.user_id,
        ut_id: 0,
        ut_type: '',
        ut_title: '',
        ut_description: '',
        ut_url: '',
        ut_start_datetime: '',
        ut_end_datetime: '',
        ut_fees: 0,
        ut_type_id: null,
        ut_type_table: null,
        ut_pin: 0,
        ut_publish_on: null,
        ut_publish_status: 1,
    });
    const [ut_attachment_1, set_ut_attachment_1] = useState(null);
    const [ut_attachment_2, set_ut_attachment_2] = useState(null);
    const [start_datetime, set_start_datetime] = useState('');
    const [end_datetime, set_end_datetime] = useState('');
    const [user_timeline_media, set_user_timeline_media] = useState([]);

    // UT post add or update
    const UTpostAddUpdate = (post) => {
        toggleOffCanvas();

        setPostDraft({
            ...postDraft,
            ...post,
        });

        if (post.ut_attachment_1) {
            set_ut_attachment_1(basePath + post.ut_attachment_1);
        }
        if (post.ut_attachment_2) {
            set_ut_attachment_2(basePath + post.ut_attachment_2);
        }
        if (post.user_timeline_media.length > 0) {
            const user_timeline_media_arr = [];
            post.user_timeline_media.map((item) =>
                user_timeline_media_arr.push(basePathMedia + item.utm_name)
            );
            set_user_timeline_media(user_timeline_media_arr);
        }
    };

    const UTTypes = [
        {
            name: 'Notification',
            icon: 'bell',
            func: UTpostAddUpdate,
        },
        {
            name: 'Newsletter',
            icon: 'mail',
            func: UTpostAddUpdate,
        },
        {
            name: 'Event',
            icon: 'calendar',
            func: UTpostAddUpdate,
        },
        {
            name: 'Media',
            icon: 'image',
            func: UTpostAddUpdate,
        },
        {
            name: 'Video',
            icon: 'video',
            func: UTpostAddUpdate,
        },
        // {
        //     name: 'Poll',
        //     icon: 'bar-chart-2',
        //     func: UTpostAddUpdate,
        // },
        // {
        //     name: 'Survey',
        //     icon: 'edit-3',
        //     func: UTpostAddUpdate,
        // },
    ];

    // Fetch user timeline
    const fetchUserTimeline = async () => {
        setFiltering(true);

        const dateFrom = moment(dateRange[0]).format('YYYY-MM-DD');
        const dateTo = moment(dateRange[1]).format('YYYY-MM-DD');

        const fData = new FormData();
        fData.append('user_id', user.user_id);
        fData.append('role_id', user.role_id);
        fData.append('center_id', user.center_id);
        fData.append('center_timezone', center.center_timezone);
        fData.append('offset', 0);
        fData.append('selectedTimelineTypes', JSON.stringify(selectedTypes));
        fData.append('activeFilterDateFrom', dateFrom);
        fData.append('activeFilterDateTo', dateTo);
        const res = await axiosPOST('user-timeline/fetch', fData);
        if (res) {
            setFiltering(false);
            setPosts(res);
        }
    };

    // Type badge color
    const typeColor = (type) => {
        let color;
        switch (type) {
            case 'Notification':
                color = 'danger';
                break;
            case 'Newsletter':
                color = 'warning';
                break;
            case 'Event':
                color = 'primary';
                break;
            case 'Media':
                color = 'success';
                break;
            case 'Video':
                color = 'info';
                break;
            // case 'Poll':
            //     color = 'warning';
            //     break;
            // case 'Survey':
            //     color = 'secondary';
            //     break;
            default:
                color = '';
        }

        return color;
    };

    // Timeline post
    const UTPost = (item, index) => {
        console.log('user_timeline_media', item.user_timeline_media.length);

        const gallery_images = [];
        item.user_timeline_media.map((item) =>
            gallery_images.push(basePathMedia + item.utm_name)
        );

        let gallery = '';

        if (item.user_timeline_media.length === 1) {
            gallery = gallery = (
                <img
                    className='card-img rounded-0 img-fluid cursor-pointer'
                    src={basePathMedia + item.user_timeline_media[0].utm_name}
                    alt={item.ut_title}
                    onClick={() => {
                        setImages(gallery_images);
                        setPhotoIndex(0);
                        setIsOpen(true);
                    }}
                />
            );
        } else if (item.user_timeline_media.length === 2) {
            gallery = (
                <Container className='mb-3 px-3'>
                    <Row className='gx-3'>
                        <Col>
                            <div
                                className='gallery-thumb'
                                style={{
                                    backgroundImage: `url(${basePathMedia +
                                        item.user_timeline_media[0].utm_name
                                        })`,
                                }}
                                onClick={() => {
                                    setImages(gallery_images);
                                    setPhotoIndex(0);
                                    setIsOpen(true);
                                }}></div>
                        </Col>
                        <Col>
                            <div
                                className='gallery-thumb'
                                style={{
                                    backgroundImage: `url(${basePathMedia +
                                        item.user_timeline_media[1].utm_name
                                        })`,
                                }}
                                onClick={() => {
                                    setImages(gallery_images);
                                    setPhotoIndex(1);
                                    setIsOpen(true);
                                }}></div>
                        </Col>
                    </Row>
                </Container>
            );
        } else if (item.user_timeline_media.length >= 3) {
            gallery = (
                <Container className='mb-3 px-3'>
                    <Row className='gx-3'>
                        <Col>
                            <div
                                className='gallery-thumb'
                                style={{
                                    height: 217,
                                    backgroundImage: `url(${basePathMedia +
                                        item.user_timeline_media[0].utm_name
                                        })`,
                                }}
                                onClick={() => {
                                    setImages(gallery_images);
                                    setPhotoIndex(0);
                                    setIsOpen(true);
                                }}></div>
                        </Col>
                        <Col>
                            <div
                                className='gallery-thumb mb-3'
                                style={{
                                    height: 100,
                                    backgroundImage: `url(${basePathMedia +
                                        item.user_timeline_media[1].utm_name
                                        })`,
                                }}
                                onClick={() => {
                                    setImages(gallery_images);
                                    setPhotoIndex(1);
                                    setIsOpen(true);
                                }}></div>
                            <div
                                className='gallery-thumb'
                                style={{
                                    height: 100,
                                    backgroundImage: `url(${basePathMedia +
                                        item.user_timeline_media[2].utm_name
                                        })`,
                                }}
                                onClick={() => {
                                    setImages(gallery_images);
                                    setPhotoIndex(2);
                                    setIsOpen(true);
                                }}>
                                {item.user_timeline_media.length > 3 && (
                                    <div
                                        style={{
                                            width: '100%',
                                            height: 100,
                                            backgroundColor:
                                                'rgba(0, 0, 0, 0.7)',
                                            color: '#ffffff',
                                            fontSize: '30px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}>
                                        {item.user_timeline_media.length - 3}+
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                </Container>
            );
        }

        return (
            <Card className='ut-post' key={index}>
                <CardBody>
                    <Row>
                        <Col>
                            <h4 className='card-title mb-2'>
                                {item.ut_title}{' '}
                            </h4>
                        </Col>
                        <Col className='col-sm-auto col-auto'>
                            <Badge color={typeColor(item.ut_type)}>
                                {item.ut_type}
                            </Badge>
                        </Col>
                    </Row>
                    <p className='card-text text-muted'>
                        {item.ut_description}
                    </p>
                    {item.ut_start_datetime && item.ut_end_datetime && (
                        <p>
                            <strong>{translate('Start Date/Time:')}</strong>{' '}
                            {moment(item.ut_start_datetime).format(
                                'D MMM, YYYY - h:mm:ss a'
                            )}
                            <br />
                            <strong>{translate('End Date/Time:')}</strong>{' '}
                            {moment(item.ut_end_datetime).format(
                                'D MMM, YYYY - h:mm:ss a'
                            )}
                        </p>
                    )}
                    {item.ut_attachment_2 && (
                        <p className='card-text text-muted'>
                            <a
                                rel='noreferrer'
                                target='_blank'
                                href={basePath + item.ut_attachment_2}
                                className='link-success'>
                                <i className='ri-attachment-2 align-middle ms-1 lh-1 me-2'></i>
                                {item.ut_attachment_2}
                            </a>
                        </p>
                    )}
                </CardBody>
                {item.ut_attachment_1 && (
                    <img
                        className='card-img rounded-0 img-fluid cursor-pointer'
                        src={basePath + item.ut_attachment_1}
                        alt={item.ut_title}
                        onClick={() => {
                            setImages([basePath + item.ut_attachment_1]);
                            setIsOpen(true);
                        }}
                    />
                )}
                {item.user_timeline_media.length > 0 && gallery}
                <div className='card-footer'>
                    <div className='card-text text-muted mb-0 d-flex justify-content-between align-items-center'>
                        <span>
                            <FeatherIcon
                                icon='clock'
                                style={{
                                    position: 'relative',
                                    top: -1,
                                    marginRight: 3,
                                }}
                                size={14}
                            />{' '}
                            {moment(item.updated_on).fromNow()}
                        </span>
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle
                                    tag='button'
                                    className='btn btn-sm btn-light'>
                                    <FeatherIcon
                                        size={12}
                                        icon='more-vertical'
                                    />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        onClick={() => UTpostAddUpdate(item)}>
                                        <FeatherIcon
                                            style={{ marginTop: -2 }}
                                            size={12}
                                            icon='edit-2'
                                        />{' '}
                                        {translate('Edit')}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                    </div>
                </div>
            </Card>
        );
    };

    // Toggle off canvas
    const toggleOffCanvas = () => {
        setOffCanvasOpen(!offCanvasOpen);
    };

    // Handle submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Worst way to do it 😕
        postDraft.ut_start_datetime = start_datetime;
        postDraft.ut_end_datetime = end_datetime;

        console.log('postDraft', postDraft);

        set_form_processing(true);

        const fData = new FormData();
        fData.append('center_id', user.center_id);
        fData.append('center_timezone', center.center_timezone);
        fData.append('user_id', user.user_id);
        fData.append('ut_id', postDraft.ut_id);
        fData.append('ut_type', postDraft.ut_type);
        fData.append('user_timeline', JSON.stringify(postDraft));
        if (ut_attachment_1 && typeof ut_attachment_1[0].source !== 'string') {
            fData.set('ut_attachment_1', ut_attachment_1[0].file);
        }
        if (
            ut_attachment_2 !== null &&
            typeof ut_attachment_2[0].source !== 'string'
        ) {
            fData.set('ut_attachment_2', ut_attachment_2[0].file);
        }

        if (user_timeline_media.length > 0) {
            user_timeline_media.map((item) => {
                if (typeof item.source !== 'string') {
                    fData.append('user_timeline_media[]', item.file);
                }
            });
        }

        try {
            const res = await axios.post(
                process.env.REACT_APP_API_URL +
                '/user-timeline/save-user-timeline',
                fData,
                {
                    params: {
                        auth_key: user.auth_key,
                    },
                }
            );

            if (res.type) {
                showToast(res.message, 'success');
                set_form_processing(false);
                fetchUserTimeline();
                resetForm();
            }

            if (!res.type) {
                showToast(res.message, 'danger');
                setValidationErrors(res.data);
                set_form_processing(false);
            }
        } catch (error) {
            showToast(error, 'danger');
            set_form_processing(false);
        }
    };

    const handleType = (type) => {
        setSelectedTypes(type);
    };

    // Reset form
    const resetForm = () => {
        setPostDraft({
            center_id: user.center_id,
            center_timezone: center.center_timezone,
            user_id: user.user_id,
            ut_id: 0,
            ut_type: '',
            ut_title: '',
            ut_description: '',
            ut_url: '',
            ut_fees: 0,
            ut_type_id: null,
            ut_type_table: null,
            ut_pin: 0,
            ut_publish_on: null,
            ut_publish_status: 1,
        });
        set_ut_attachment_1(null);
        set_ut_attachment_2(null);
        set_start_datetime('');
        set_end_datetime('');
        set_user_timeline_media([]);
        setValidationErrors({});
        set_form_processing(false);
        toggleOffCanvas();
    };

    console.log('postDraft', postDraft);

    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>User Timeline</h4>
                            </div>
                        </Col>
                    </Row>
                    <Row className='g-3'>
                        {UTTypes.map((item, index) => (
                            <Col className='col' key={index}>
                                <Card
                                    onClick={() => {
                                        UTpostAddUpdate({ ut_type: item.name });
                                    }}
                                    className={'card-animate cursor-pointer'}>
                                    <CardBody>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div>
                                                <p className='fw-medium mb-0 text-muted'>
                                                    {translate(item.name)}
                                                </p>
                                            </div>
                                            <div className='avatar-sm flex-shrink-0'>
                                                <span
                                                    style={{
                                                        backgroundColor:
                                                            '#edfffc',
                                                    }}
                                                    className={
                                                        'avatar-title rounded-circle fs-2'
                                                    }>
                                                    <FeatherIcon
                                                        style={{
                                                            color: '#3DB19B',
                                                        }}
                                                        icon={item.icon}
                                                    />
                                                </span>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                    <Card>
                        <CardBody>
                            <Row className='g-2'>
                                <Col sm={4}>
                                    <div className='search-box'>
                                        <Input
                                            onChange={(e) =>
                                                setPostsSearchKeyword(
                                                    e.target.value
                                                )
                                            }
                                            value={postsSearchKeyword}
                                            type='text'
                                            className='form-control'
                                            placeholder='Search keyword...'
                                        />
                                        <i className='ri-search-line search-icon'></i>
                                    </div>
                                </Col>
                                <Col sm={3}>
                                    <Flatpickr
                                        className='form-control'
                                        placeholder='Date range'
                                        options={{
                                            mode: 'range',
                                            dateFormat: 'd-m-Y',
                                            defaultDate: dateRange,
                                        }}
                                        onChange={([dateFrom, dateTo]) => {
                                            setDateRange([dateFrom, dateTo]);
                                        }}
                                    />
                                </Col>
                                <Col sm={4}>
                                    <Select
                                        isMulti={true}
                                        styles={{
                                            control: () => ({
                                                borderColor: '#CFD4DA',
                                                borderWidth: 1,
                                                borderStyle: 'solid',
                                                display: 'flex',
                                                borderRadius: 4,
                                                height: 37,
                                            }),
                                        }}
                                        name='Services[services_type]'
                                        onChange={() => {
                                            handleType();
                                        }}
                                        options={typeOptions}
                                        value={selectedTypes}
                                        placeholder='Select types...'
                                    />
                                </Col>
                                <Col className=''>
                                    <div className='d-grid'>
                                        <Button
                                            onClick={() => fetchUserTimeline()}
                                            color='success'>
                                            FILTER
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    {filtering ? (
                        <Row>
                            <Col className='text-center'>
                                <Spinner color='danger' />
                            </Col>
                        </Row>
                    ) : (
                        <React.Fragment>
                            {posts.length == 0 ? (
                                <Row>
                                    <Col>
                                        <p className='pt-3 text-muted text-center'>
                                            No posts were found!
                                        </p>
                                    </Col>
                                </Row>
                            ) : (
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className='my-masonry-grid'
                                    columnClassName='my-masonry-grid_column'>
                                    {posts
                                        .filter(
                                            (item) =>
                                                item.ut_title
                                                    .toLowerCase()
                                                    .includes(
                                                        postsSearchKeyword
                                                    ) ||
                                                postsSearchKeyword === ''
                                        )
                                        .map((item, index) =>
                                            UTPost(item, index)
                                        )}
                                </Masonry>
                            )}
                        </React.Fragment>
                    )}
                </Container>
                <Offcanvas
                    style={{ width: offCanvasWidth }}
                    isOpen={offCanvasOpen}
                    toggle={resetForm}
                    direction='end'
                    className='offcanvas-end border-0'>
                    <OffcanvasHeader
                        className='d-flex align-items-center p-3 border-bottom'
                        toggle={resetForm}>
                        <span className='m-0 me-2'>
                            {postDraft.ut_id == 0
                                ? 'Add ' + postDraft.ut_type
                                : 'Update ' + postDraft.ut_type}
                        </span>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Form
                            onSubmit={handleSubmit}
                            id='data-form'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                            }}>
                            <div style={{ flex: 1 }}>
                                <Row>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label className='ut_title'>
                                                {translate('Title')}
                                            </Label>
                                            <Input
                                                name='ut_title'
                                                value={postDraft.ut_title}
                                                onChange={(e) =>
                                                    setPostDraft({
                                                        ...postDraft,
                                                        ut_title:
                                                            e.target.value,
                                                    })
                                                }
                                                type='text'
                                                className='form-control'
                                                placeholder=''
                                                id='ut_title'
                                                invalid={
                                                    handleFormError(
                                                        'ut_title',
                                                        validationErrors
                                                    ).show
                                                }
                                            />
                                            <FormFeedback>
                                                {
                                                    handleFormError(
                                                        'ut_title',
                                                        validationErrors
                                                    ).msg
                                                }
                                            </FormFeedback>
                                        </div>
                                    </Col>
                                </Row>
                                {postDraft.ut_type === 'Event' && (
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <Label
                                                    htmlFor='ut_start_datetime'
                                                    className='form-label'>
                                                    {translate('Start Date')}
                                                </Label>
                                                <Flatpickr
                                                    name='ut_start_datetime'
                                                    id='ut_start_datetime'
                                                    value={
                                                        postDraft.ut_start_datetime
                                                    }
                                                    onChange={([dateStart]) =>
                                                        set_start_datetime(
                                                            moment(
                                                                dateStart
                                                            ).format(
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            )
                                                        )
                                                    }
                                                    className='form-control'
                                                    options={{
                                                        enableTime: true,
                                                        dateFormat: 'Y-m-d H:i',
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <Label
                                                    htmlFor='ut_end_datetime'
                                                    className='form-label'>
                                                    {translate('End Date')}
                                                </Label>
                                                <Flatpickr
                                                    name='ut_end_datetime'
                                                    id='ut_end_datetime'
                                                    value={
                                                        postDraft.ut_end_datetime
                                                    }
                                                    className='form-control'
                                                    options={{
                                                        enableTime: true,
                                                        dateFormat: 'Y-m-d H:i',
                                                    }}
                                                    onChange={([dateEnd]) =>
                                                        set_end_datetime(
                                                            moment(
                                                                dateEnd
                                                            ).format(
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            )
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col md={12}>
                                        <div className='mb-3'>
                                            <Label className='ut_description'>
                                                {translate('Description')}
                                            </Label>
                                            <textarea
                                                value={postDraft.ut_description}
                                                onChange={(e) =>
                                                    setPostDraft({
                                                        ...postDraft,
                                                        ut_description:
                                                            e.target.value,
                                                    })
                                                }
                                                name='ut_description'
                                                className='form-control'
                                                id='ut_description'
                                                rows='3'></textarea>
                                        </div>
                                    </Col>
                                </Row>
                                {postDraft.ut_type === 'Media' && (
                                    <Row>
                                        <Col md={12}>
                                            <div className='mb-3'>
                                                <Label className='user_timeline_media'>
                                                    {translate('Media')}
                                                </Label>
                                                <FilePond
                                                    files={user_timeline_media}
                                                    onupdatefiles={
                                                        set_user_timeline_media
                                                    }
                                                    allowMultiple={true}
                                                    name='user_timeline_media'
                                                    className='filepond filepond-input'
                                                    labelIdle='Drag & Drop your images or <span class="filepond--label-action">Browse</span>'
                                                    acceptedFileTypes={[
                                                        'image/*',
                                                    ]}
                                                    fileValidateTypeDetectType={(
                                                        source,
                                                        type
                                                    ) =>
                                                        new Promise(
                                                            (resolve, reject) =>
                                                                resolve(type)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {postDraft.ut_type !== 'Media' && (
                                    <Row>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <Label className='ut_attachment_1'>
                                                    {translate('Custom Icon')}
                                                </Label>
                                                <FilePond
                                                    files={ut_attachment_1}
                                                    onupdatefiles={
                                                        set_ut_attachment_1
                                                    }
                                                    allowMultiple={false}
                                                    name='ut_attachment_1'
                                                    className='filepond filepond-input'
                                                    labelIdle='Drag & Drop your image or <span class="filepond--label-action">Browse</span>'
                                                    acceptedFileTypes={[
                                                        'image/*',
                                                    ]}
                                                    fileValidateTypeDetectType={(
                                                        source,
                                                        type
                                                    ) =>
                                                        new Promise(
                                                            (resolve, reject) =>
                                                                resolve(type)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col md={6}>
                                            <div className='mb-3'>
                                                <Label className='ut_attachment_2'>
                                                    {translate('Attachment')}
                                                </Label>
                                                <FilePond
                                                    files={ut_attachment_2}
                                                    onupdatefiles={
                                                        set_ut_attachment_2
                                                    }
                                                    allowMultiple={false}
                                                    name='ut_attachment_2'
                                                    className='filepond filepond-input'
                                                    labelIdle='Drag & Drop your file or <span class="filepond--label-action">Browse</span>'
                                                    acceptedFileTypes={[
                                                        'application/pdf',
                                                    ]}
                                                    fileValidateTypeDetectType={(
                                                        source,
                                                        type
                                                    ) =>
                                                        new Promise(
                                                            (resolve, reject) =>
                                                                resolve(type)
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>
                            <div>
                                <Row>
                                    <Col md={12}>
                                        <div className='text-end mb-4'>
                                            <Button
                                                disabled={form_processing}
                                                color={
                                                    postDraft.ut_id == 0
                                                        ? 'success'
                                                        : 'warning'
                                                }
                                                className='btn-load'>
                                                <span className='d-flex align-items-center'>
                                                    {form_processing && (
                                                        <Spinner
                                                            size='sm'
                                                            className='flex-shrink-0 me-2'></Spinner>
                                                    )}
                                                    <span className='flex-grow-1'>
                                                        {postDraft.ut_id == 0
                                                            ? 'Add ' +
                                                            postDraft.ut_type
                                                            : 'Update ' +
                                                            postDraft.ut_type}
                                                    </span>
                                                </span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </OffcanvasBody>
                </Offcanvas>
            </div>
            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    onCloseRequest={() => setIsOpen(false)}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={
                        images[(photoIndex + images.length - 1) % images.length]
                    }
                    onMovePrevRequest={() =>
                        setPhotoIndex(
                            (photoIndex + images.length - 1) % images.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % images.length)
                    }
                />
            )}
        </React.Fragment>
    );
};

export default UserTimeline;
