import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, } from 'reactstrap';
import ParticlesAuth from '../AuthenticationInner/ParticlesAuth';

//redux
import { useSelector, useDispatch } from 'react-redux';

import { withRouter, Link, useParams } from 'react-router-dom';
// Formik validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

import logoLight from '../../assets/images/logo-light.png';
import { axiosPOST } from '../../helpers/datatable_helper';
import { showToast } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';
import moment from 'moment';

const QrRead = (props) => {

    document.title = 'Kinderiffic QR';

    let { id } = useParams();


    const [invData, setInvData] = useState(null);

    // Use effect
    useEffect(() => {
        try {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/invoice/edit?auth_key=${'qr-view'}&inv_id=${id}`
                )
                .then((res) => {
                    setInvData(res);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    console.log('invData', invData);
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className='auth-page-content'>
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className='text-center mt-sm-5 mb-4 text-white-50'>
                                    <div>
                                        <Link
                                            to='/'
                                            className='d-inline-block auth-logo'>
                                            <img
                                                src={logoLight}
                                                alt=''
                                                height='80'
                                            />
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {(invData) &&
                                    <Card className='mt-4'>
                                        <CardBody className='p-4'>
                                            <div className='d-flex flex-row border-bottom justify-content-center' style={{ height: '50px' }}>
                                                <div style={{ position: 'absolute', left: 10, top: 10 }}>
                                                    <img src={process.env.REACT_APP_API_URL + '/web/attachments/centers/' + invData.data.center.center_id + 'centers/' + invData.data.center.logo} alt='' height='50' />
                                                </div>
                                                <h4 className='mt-1 mb-1'>{invData.data.center.center_name}</h4>
                                            </div>
                                            <div className='row border-bottom pt-2 pb-2'>
                                                <div className='col-md-6'>
                                                    <div className='d-flex flex-column justify-content-end'>
                                                        <div><span style={{ width: '80px', display: 'inline-block', fontWeight: 'bold' }}>Phone </span> {invData.data.center.phone1}</div>
                                                        <div><span style={{ width: '80px', display: 'inline-block', fontWeight: 'bold' }}>Website </span> {invData.data.center.urlwww}</div>
                                                        <div><span style={{ width: '80px', display: 'inline-block', fontWeight: 'bold' }}>Email </span> {invData.data.center.email1}</div>
                                                        <div><span style={{ width: '80px', display: 'inline-block', fontWeight: 'bold' }}>Address </span> {invData.data.center.address}</div>
                                                        <div><span style={{ width: '80px', display: 'inline-block', fontWeight: 'bold' }}>TRN </span> {invData.data.center.vat_num}</div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className='d-flex justify-content-end'>
                                                        <div><span style={{ width: '60px', display: 'inline-block', fontWeight: 'bold' }}>To </span> {invData.data.invoice.parent_name}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row border-bottom'>
                                                <div className='col-md-12 text-center'>
                                                    <h5 className='mt-1 mb-1'>Simplified Tax Invoice</h5>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-between pt-2'>
                                                <div className='text-center'>
                                                    <h5>Date</h5>
                                                    <p>{moment(invData.data.invoice.inv_date).format('DD-MM-YYYY')}</p>
                                                </div>
                                                <div className='text-center'>
                                                    <h5>Invoice No</h5>
                                                    <p>{invData.data.invoice.inv_num}</p>
                                                </div>
                                                <div className='text-center'>
                                                    <h5>Total VAT</h5>
                                                    <p>{invData.data.invoice.inv_vat}</p>
                                                </div>
                                                <div className='text-center'>
                                                    <h4>Grand Total</h4>
                                                    <strong>{invData.data.invoice.inv_amount}</strong>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                }
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(QrRead);
