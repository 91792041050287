import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, Row, Form, FormFeedback, Label, Input, Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Card, CardBody, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Spinner, ButtonGroup, CardHeader, UncontrolledCollapse, Nav, NavItem, NavLink, TabContent, TabPane, Badge } from 'reactstrap';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { justNums, handleFormError, showToast, getCountries, getDDLOptions, } from '../../helpers/common_helper';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DataTable from 'react-data-table-component';
import FeatherIcon from 'feather-icons-react';
import { CSVLink } from 'react-csv';
import { axiosPOST, CSVHeader, PDFExport, tableFetch, CSVExport, dtColumnConfig, axiosGET, } from '../../helpers/datatable_helper';
import Swal from 'sweetalert2';
import moment from 'moment';
import { translate } from '../../helpers/language_helper';
import { FilePond, registerPlugin } from 'react-filepond';
import { Link } from 'react-router-dom';
import Nouislider from 'nouislider-react';
import Flatpickr from "react-flatpickr";

const Collection = () => {
    document.title = 'Collection Report | Kinderiffic';
    const offCanvasWidth = '35%';

    // States-E-Module
    const { USER, CENTER, layoutModeType } = useSelector((state) => ({
        USER: state.User,
        CENTER: state.Center,
        layoutModeType: state.Layout.layoutModeType
    }));

    const [state, setState] = useState({
        form_processing: false,
        loading: false,
        validationErrors: '',
        allEmployees: [],
        filteredEmployees: []
    });

    const [Filter, setFilter] = useState({
        daterange: moment().format('DD-MM-YYYY'),
        user_ids: [],
        dtTotalAmount: 0,
    });

    const [stateDT, setStateDT] = useState({
        // START DT START
        // THIS MUST BE DEFINED IF DATATABLES ARE BEING USED FOR SHOW/HIDE COLS
        dtCols: {},
        dtSort: {
            field: 'receipt_id',
            dir: 'DESC',
        },
        dtData: [],
        dtExportCSV: [],
        dtTotalRows: 0,
        dtPerPage: 10,
        dtCurrentPage: 1,
        dtSearchKeyword: '',
        statusAll: false,
        loading: false,
        // START DT END
    });


    // Datatable: columns
    // BASED ON THESE STATES IT WILL FETCH THE DATA AGAIN
    useEffect(() => {
        fetchData(stateDT.dtCurrentPage);
    }, [stateDT.dtSort, stateDT.dtSearchKeyword, Filter.daterange, Filter.user_ids.length]);
    const dtColumns = [
        {
            ...dtColumnConfig('receipt_num', stateDT),
            name: translate('Receipt Num'),
        },
        {
            ...dtColumnConfig('receipt_amount', stateDT),
            name: translate('Amount')
        },
        {
            ...dtColumnConfig('receipt_desc', stateDT),
            name: translate('Description'),
            width: '400px',
            selector: (row) => (
                <span style={{ whiteSpace: 'normal' }}>
                    {row.receipt_desc}
                </span>
            )
        },
        {
            ...dtColumnConfig('created_by_user', stateDT),
            name: translate('Created By'),
            width: '200px',
            selector: (row) => (
                <span style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                    <div>{row.created_by_user}</div>
                </span>
            )
        },
        {
            ...dtColumnConfig('created_on', stateDT),
            name: translate('Created On'),
            width: '200px',
            selector: (row) => (
                <span style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                    <Badge color="primary">{moment(row.created_on).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                </span>
            )
        },
        {
            ...dtColumnConfig('updated_by_user', stateDT),
            name: translate('Updated By'),
            width: '200px',
            selector: (row) =>
                (row.updated_on) ? (
                    <span style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                        <div>{row.updated_by_user}</div>
                    </span>
                ) : '-'
        },
        {
            ...dtColumnConfig('updated_on', stateDT),
            name: translate('Updated On'),
            width: '200px',
            selector: (row) =>
                (row.updated_on) ? (
                    <span style={{ whiteSpace: 'normal', textAlign: 'center' }}>
                        <Badge color="warning">{moment(row.updated_on).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                    </span>
                ) : '-'
        },
    ];
    // Datatable: header component
    const subHeaderComponent = (
        <>
            {/* CSV,PDF DOWNLOAD BTNS */}
            <ButtonGroup className='btn btn-sm' size='sm'>
                <CSVLink
                    headers={CSVHeader(dtColumns)}
                    filename={'csv_' + moment().unix() + '.csv'}
                    data={stateDT.dtExportCSV}
                    asyncOnClick={true}
                    onClick={(event, done) =>
                        CSVExport(
                            dtColumns,
                            stateDT.dtData,
                            stateDT,
                            setStateDT,
                            done
                        )
                    }
                    className='btn btn-primary btn-sm'>
                    {translate('CSV')}
                </CSVLink>
                <Button
                    className='btn btn-primary btn-sm'
                    onClick={() => PDFExport(dtColumns, stateDT.dtData)}>
                    {translate('PDF')}
                </Button>
            </ButtonGroup>

            <UncontrolledDropdown className='me-3'>
                <DropdownToggle
                    tag='button'
                    className='btn btn-light btn-sm'
                    id='dropdownMenuButton'>
                    {translate('Columns Visibility')}{' '}
                    <i className='mdi mdi-chevron-down'></i>
                </DropdownToggle>
                <DropdownMenu>
                    {dtColumns.map(
                        (item, index) =>
                            item.key != null && (
                                <DropdownItem
                                    key={index}
                                    onClick={() =>
                                        setStateDT({
                                            ...stateDT,
                                            dtCols: {
                                                ...stateDT.dtCols,
                                                [item.key]:
                                                    !stateDT.dtCols[item.key],
                                            },
                                        })
                                    }
                                    className={item.omit ? 'text-muted' : ''}>
                                    <FeatherIcon
                                        className='me-1'
                                        icon={item.omit ? 'eye-off' : 'eye'}
                                        size={14}
                                    />{' '}
                                    {item.name}
                                </DropdownItem>
                            )
                    )}
                </DropdownMenu>
            </UncontrolledDropdown>
            <div className='form-icon right'>
                <Input
                    value={stateDT.dtSearchKeyword}
                    type='text'
                    className='form-control form-control-sm form-control-icon'
                    id='iconleftInput'
                    placeholder={translate('Search here...')}
                    onChange={(e) =>
                        setStateDT({
                            ...stateDT,
                            dtSearchKeyword: e.target.value,
                        })
                    }
                />
                {stateDT.dtSearchKeyword != '' ? (
                    <i
                        onClick={() =>
                            setStateDT({ ...stateDT, dtSearchKeyword: '' })
                        }
                        className='mdi mdi-close-thick search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                ) : (
                    <i
                        className='mdi mdi-magnify search-widget-icon'
                        style={{ fontSize: '15px' }}></i>
                )}
            </div>
        </>
    );
    // Datatable: fetch data
    let fetchDataReq;
    const fetchData = async (page, newPerPage = stateDT.dtPerPage) => {
        setStateDT({ ...stateDT, loading: true });
        if (fetchDataReq) {
            fetchDataReq.abort();
        }
        fetchDataReq = new AbortController();
        let parameters = {
            center_id: USER.center_id,
            offset: (page - 1) * newPerPage,
            order: stateDT.dtSort.field,
            dir: stateDT.dtSort.dir,
            page: page,
            limit: newPerPage,
            search: stateDT.dtSearchKeyword,
            only_active: stateDT.statusAll,
            filter: Filter
        };

        const res = await tableFetch(
            'report-collection/fetch',
            fetchDataReq,
            parameters
        );
        if (res) {
            console.log('tableFetchres', res);
            setStateDT({
                ...stateDT,
                dtData: res.data,
                dtTotalRows: res.total_count_filtered,
                loading: false,
                dtCurrentPage: page,
                dtPerPage: newPerPage,
            });
            setFilter({ ...Filter, dtTotalAmount: res.total_sum })
        } else {
            setStateDT({ ...stateDT, loading: false });
        }
    };




    const filterDateRange = (days) => {
        setFilter({ ...Filter, daterange: moment().subtract(days, 'day').format('DD-MM-YYYY') + ' to ' + moment().format('DD-MM-YYYY') })
    }






    //START FOR CALLBACK STATES
    const myCallbacksList = useRef([]);
    const setStateWCall = (newState, callback) => {
        setState(newState);
        if (callback) myCallbacksList.current.push(callback);
    };
    useEffect(() => {
        myCallbacksList.current.forEach((callback) => callback(state));
        myCallbacksList.current = [];
    }, [state]);
    //END FOR CALLBACK STATES

    useEffect(() => {
        async function componentDidMount() {
            getEmployees()
        }
        componentDidMount()
    }, []);


    const getEmployees = () => {
        axiosGET('report-collection/get-employees', { center_id: CENTER.center_id }).then((res) => {
            console.log('report-collection/get-employees', res);
            setState({ ...state, allEmployees: res, filteredEmployees: res })
        })
    }

    const searchEmployee = (search) => {
        if (search == '') {
            setState({ ...state, filteredEmployees: state.allEmployees })
        } else {
            let filteredEmployees = state.allEmployees.filter(v => (v.full_name).toLowerCase().includes(search.toLowerCase()));
            setState({ ...state, filteredEmployees: filteredEmployees })
        }
    }


    console.log('Filter', Filter);
    return (
        <React.Fragment>
            <ToastContainer />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>{translate('Collection Report')}</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={3} lg={4}>
                            <Card>
                                <CardHeader >
                                    <div className="d-flex mb-3">
                                        <div className="flex-grow-1">
                                            <h5 className="fs-16">Filters</h5>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <Link to="#" className="text-decoration-underline" onClick={() => setFilter({ ...Filter, daterange: moment().format('DD-MM-YYYY'), user_ids: [] })}>
                                                Clear All
                                            </Link>
                                        </div>
                                    </div>


                                    <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                                        Date Range
                                    </p>
                                    <Flatpickr
                                        value={Filter.daterange}
                                        className="form-control"
                                        options={{
                                            dateFormat: 'd-m-Y',
                                            mode: 'range'
                                        }}
                                        onChange={(date) => {
                                            if (date.length == 2) {
                                                setFilter({ ...Filter, daterange: moment(date[0]).format('DD-MM-YYYY') + ' to ' + moment(date[1]).format('DD-MM-YYYY') })
                                            }
                                        }}
                                    />

                                </CardHeader>
                                <CardBody>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(0)}>
                                        Today
                                    </span>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(1)}>
                                        Yesterday
                                    </span>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(3)}>
                                        Last 3 Days
                                    </span>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(7)}>
                                        Last 7 Days
                                    </span>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(15)}>
                                        Last 15 Days
                                    </span>
                                    <span className="badge bg-success rounded-pill align-middle me-1 mb-2 cursor-pointer" style={{ fontSize: '14px' }}
                                        onClick={() => filterDateRange(30)}>
                                        Last 30 Days
                                    </span>
                                </CardBody>
                                <CardBody>

                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button bg-transparent shadow-none collapsed"
                                                type="button"
                                                id="flush-headingRating"
                                            >
                                                <span className="text-muted text-uppercase fs-12 fw-medium">
                                                    Collected By
                                                </span>{" "}
                                                <span className="badge bg-success rounded-pill align-middle ms-1">
                                                    {Filter.user_ids.length}
                                                </span>
                                            </button>
                                        </h2>

                                        <UncontrolledCollapse toggler="#flush-headingRating" defaultOpen>
                                            <div
                                                id="flush-collapseRating"
                                                className="accordion-collapse collapse show"
                                                aria-labelledby="flush-headingRating"
                                            >
                                                <div className="accordion-body text-body pt-0">
                                                    <div className="search-box search-box-sm mb-2">
                                                        <input
                                                            type="text"
                                                            className="form-control bg-light border-0"
                                                            placeholder="Search User..."
                                                            onChange={(e) => searchEmployee(e.target.value)}

                                                        />
                                                        <i className="ri-search-line search-icon"></i>
                                                    </div>

                                                    {/* LIST OF EMPLOYEES */}
                                                    {state.filteredEmployees.map((v, i) => {
                                                        return <div className="d-flex flex-column gap-2 mb-1" key={i}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id={"check_user_" + v.user_id}
                                                                    onChange={e => {
                                                                        let user_ids_new = Filter.user_ids
                                                                        if (e.target.checked) {
                                                                            user_ids_new.push(v.user_id)
                                                                        } else {
                                                                            user_ids_new = user_ids_new.filter((vv, ii) => v.user_id != vv)
                                                                        }
                                                                        setFilter({ ...Filter, user_ids: user_ids_new })
                                                                    }}
                                                                    checked={Filter.user_ids.includes(v.user_id)}
                                                                />
                                                                <label className="form-check-label" htmlFor={"check_user_" + v.user_id}>
                                                                    {v.full_name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    })}


                                                </div>
                                            </div>
                                        </UncontrolledCollapse>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <div className="col-xl-9 col-lg-8">
                            <div>
                                <div className="card">
                                    <div className="card-header border-0">
                                        <div className="row g-4">
                                            <div className="col-sm-auto">

                                            </div>
                                            <div className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <h4>Total Collection : {(Filter.dtTotalAmount) ? parseFloat(Filter.dtTotalAmount).toFixed(2) : 0.00}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <DataTable
                                            columns={dtColumns}
                                            data={stateDT.dtData}
                                            progressPending={stateDT.loading}
                                            pagination
                                            paginationServer
                                            paginationTotalRows={stateDT.dtTotalRows}
                                            onChangePage={(page) => fetchData(page)}
                                            onChangeRowsPerPage={(newPerPage, page) =>
                                                fetchData(page, newPerPage)
                                            }
                                            paginationComponentOptions={{
                                                selectAllRowsItem: true,
                                            }}
                                            subHeader
                                            subHeaderComponent={subHeaderComponent}
                                            onSort={(column, sortDirection) =>
                                                setStateDT({
                                                    ...stateDT,
                                                    dtSort: {
                                                        field: column.key,
                                                        dir: sortDirection.toUpperCase(),
                                                    },
                                                })
                                            }
                                            sortServer
                                            theme={
                                                layoutModeType == 'dark'
                                                    ? 'dark'
                                                    : 'default'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Row>




                </Container>

            </div>
        </React.Fragment>
    );
};

export default Collection;
