import React, { useEffect, useState } from 'react';
import {
    Col,
    Container,
    Row,
    Card,
    CardHeader,
    CardBody,
    Table,
    Button,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';

import logoDark from '../../assets/images/logo-dark.png';
import logoLight from '../../assets/images/logo-light.png';
import moment from 'moment';
import FeatherIcon from 'feather-icons-react';
import { translate } from '../../helpers/language_helper';
import { generateQRCode } from '../../helpers/common_helper';
import QRCode from 'react-qr-code';

const InvoiceDetails = (props) => {
    document.title = 'Simplified Tax Invoice';

    // States-E-Module
    const { user, center } = useSelector((state) => ({
        user: state.User,
        center: state.Center,
    }));
    const [invData, setInvData] = useState(null);

    // Use effect
    useEffect(() => {
        try {
            axios
                .get(
                    `${process.env.REACT_APP_API_URL}/invoice/edit?auth_key=${user.auth_key}&inv_id=${props.location.state.inv_id}`
                )
                .then((res) => {
                    setInvData(res);
                });
        } catch (error) {
            console.log(error);
        }
    }, []);

    //Print the Invoice
    const printInvoice = () => {
        window.print();
    };

    let history = useHistory();

    if (invData === null) return null;

    console.log('invData', invData);

    let sub_total = 0;

    const invoice = invData.data.invoice;
    const invoiceDetails = invData.data.invoicedetails.map((item, index) => {
        sub_total = parseFloat(item.invd_line_amount) + sub_total;
        return (
            <tr key={index}>
                <th scope='row'>{index + 1}</th>
                <td className='text-start'>
                    <span className='fw-medium'>{item.services_name}</span>
                </td>
                <td>{item.invd_line_amount}</td>
                <td>{item.invd_line_qty}</td>
                <td>{Math.trunc(item.invd_line_discount)}%</td>
                <td>{item.invd_line_vat}</td>
                <td className='text-end'>{item.invd_total}</td>
            </tr>
        );
    });

    let officialQR = generateQRCode(
        center.center_name,
        invoice.inv_num,
        moment(invoice.created_on).format(),
        (parseFloat(invoice.inv_amount) + parseFloat(invoice.inv_vat)).toFixed(
            2
        ),
        parseFloat(invoice.inv_vat).toFixed(2)
    );
    let kinderifficQR =
        'https://portal.kinderiffic.com/qr-read/' + props.location.state.inv_id;
    let centerLogo =
        'https://api.kinderiffic.com/web/attachments/centers/' +
        center.center_id +
        '/centers/' +
        center.logo;

    return (
        <React.Fragment>
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div
                                className='page-title-box d-sm-flex align-items-center justify-content-between'
                                style={{ minHeight: '58px' }}>
                                <h4 className='mb-sm-0'>
                                    <FeatherIcon
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => history.goBack()}
                                        className='me-1'
                                        icon='arrow-left'
                                    />
                                    {translate('Invoice Details')}
                                </h4>
                                <div className='hstack gap-2 justify-content-end d-print-none'>
                                    <Button
                                        onClick={printInvoice}
                                        color='success'>
                                        <i className='ri-printer-line align-bottom me-1'></i>{' '}
                                        {translate('Print')}
                                    </Button>
                                    <Button color='primary'>
                                        <i className='ri-mail-send-line align-bottom me-1'></i>{' '}
                                        {translate('Email PDF')}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='justify-content-center'>
                        <Col xxl={9}>
                            <Card id='demo'>
                                <Row>
                                    <Col lg={12}>
                                        <CardHeader className='border-bottom-dashed p-4'>
                                            <div className='d-flex flex-grow-1 justify-content-center align-items-center border-bottom mb-2'>
                                                <h4>
                                                    {translate(
                                                        'Simplified Tax Invoice'
                                                    )}
                                                </h4>
                                            </div>
                                            <div className='d-flex'>
                                                <div className='flex-grow-1'>
                                                    <img
                                                        src={centerLogo}
                                                        className='card-logo card-logo-dark'
                                                        alt={center.center_name}
                                                        height='60'
                                                    />
                                                    <div className='mt-sm-5 mt-4'>
                                                        <h6 className='text-muted text-uppercase fw-semibold mb-0'>
                                                            {translate(
                                                                'ADDRESS'
                                                            )}
                                                        </h6>
                                                        <p
                                                            className='text-muted mb-1'
                                                            id='address-details'>
                                                            {center.address}
                                                        </p>
                                                        <h6 className='text-muted text-uppercase fw-semibold mb-0'>
                                                            {translate(
                                                                'VAT NUMBER'
                                                            )}
                                                        </h6>
                                                        <p
                                                            className='text-muted mb-1'
                                                            id='address-details'>
                                                            {center.vat_num}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='flex-shrink-0 mt-sm-0 mt-3'>
                                                    <h6>
                                                        <span className='text-muted fw-normal'>
                                                            {translate('Email')}
                                                        </span>{' '}
                                                        <span id='email'>
                                                            {center.email1}
                                                        </span>
                                                    </h6>
                                                    <h6>
                                                        <span className='text-muted fw-normal'>
                                                            {translate(
                                                                'Website'
                                                            )}
                                                        </span>{' '}
                                                        <Link
                                                            to={center.urlwww}
                                                            className='link-primary'
                                                            id='website'>
                                                            {center.urlwww}
                                                        </Link>
                                                    </h6>
                                                    <h6 className='mb-0'>
                                                        <span className='text-muted fw-normal'>
                                                            {translate(
                                                                'Contact No'
                                                            )}
                                                        </span>{' '}
                                                        <span id='contact-no'>
                                                            {' '}
                                                            {center.phone1}
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </CardHeader>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4'>
                                            <Row className='g-3'>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        {translate(
                                                            'INVOICE NO'
                                                        )}
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='invoice-no'>
                                                            {invoice.inv_num}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        {translate('DATE')}
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='invoice-date'>
                                                            {moment(
                                                                invoice.inv_data
                                                            ).format(
                                                                'DD-MM-YYYY'
                                                            )}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        {translate('VAT')}
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='total-amount'>
                                                            {invoice.inv_vat}
                                                        </span>
                                                    </h5>
                                                </Col>
                                                <Col lg={3} xs={6}>
                                                    <p className='text-muted mb-2 text-uppercase fw-semibold'>
                                                        {translate(
                                                            'TOTAL AMOUNT'
                                                        )}
                                                    </p>
                                                    <h5 className='fs-14 mb-0'>
                                                        <span id='total-amount'>
                                                            {invoice.inv_amount}
                                                        </span>
                                                    </h5>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4 border-top border-top-dashed'>
                                            <Row className='g-3'>
                                                <Col lg={6} xs={6}>
                                                    <h6 className='text-muted text-uppercase fw-semibold mb-3'>
                                                        {translate(
                                                            'BILLING TO'
                                                        )}
                                                    </h6>
                                                    <p
                                                        className='fw-medium mb-2'
                                                        id='billing-name'>
                                                        {invoice.parent_name}
                                                    </p>
                                                    {invoice.address && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.address}
                                                        </p>
                                                    )}
                                                    {invoice.parent_country && (
                                                        <p className='text-muted mb-1'>
                                                            {
                                                                invoice.parent_country
                                                            }
                                                        </p>
                                                    )}
                                                    {invoice.email1 && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.email1}
                                                        </p>
                                                    )}
                                                    {invoice.phone1 && (
                                                        <p className='text-muted mb-1'>
                                                            {invoice.phone1}
                                                        </p>
                                                    )}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Col>
                                    <Col lg={12}>
                                        <CardBody className='p-4'>
                                            <div className='table-responsive'>
                                                <Table className='table-borderless text-center table-nowrap align-middle mb-0'>
                                                    <thead>
                                                        <tr className='table-active'>
                                                            <th
                                                                scope='col'
                                                                style={{
                                                                    width: '50px',
                                                                }}>
                                                                #
                                                            </th>
                                                            <th
                                                                scope='col'
                                                                className='text-start'>
                                                                {translate(
                                                                    'Service'
                                                                )}
                                                            </th>
                                                            <th scope='col'>
                                                                {translate(
                                                                    'Amount'
                                                                )}
                                                            </th>
                                                            <th scope='col'>
                                                                {translate(
                                                                    'Quantity'
                                                                )}
                                                            </th>
                                                            <th scope='col'>
                                                                {translate(
                                                                    'Discount'
                                                                )}
                                                            </th>
                                                            <th scope='col'>
                                                                {translate(
                                                                    'VAT'
                                                                )}
                                                            </th>
                                                            <th
                                                                scope='col'
                                                                className='text-end'>
                                                                {translate(
                                                                    'Total'
                                                                )}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody id='products-list'>
                                                        {invoiceDetails}
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div className='border-top border-top-dashed mt-2 d-flex flex-row justify-content-end'>
                                                <div
                                                    className='d-flex flex-row justify-content-around'
                                                    style={{
                                                        width: '400px',
                                                        paddingTop: '10px',
                                                    }}>
                                                    <div
                                                        style={{
                                                            height: 'auto',
                                                            margin: '0 auto',
                                                            maxWidth: 100,
                                                            width: '100%',
                                                            textAlign: 'center',
                                                        }}>
                                                        <QRCode
                                                            style={{
                                                                height: 'auto',
                                                                maxWidth:
                                                                    '100%',
                                                                width: '100%',
                                                            }}
                                                            value={officialQR}
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                        <span>
                                                            {translate(
                                                                'Official'
                                                            )}
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{
                                                            height: 'auto',
                                                            margin: '0 auto',
                                                            maxWidth: 100,
                                                            width: '100%',
                                                            textAlign: 'center',
                                                        }}>
                                                        <QRCode
                                                            style={{
                                                                height: 'auto',
                                                                maxWidth:
                                                                    '100%',
                                                                width: '100%',
                                                            }}
                                                            value={
                                                                kinderifficQR
                                                            }
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                        <span>
                                                            {translate('View')}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='d-flex flex-row'>
                                                    <Table
                                                        className='table table-borderless table-nowrap align-middle mb-0 ms-auto'
                                                        style={{
                                                            width: '250px',
                                                        }}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {translate(
                                                                        'Sub Total'
                                                                    )}
                                                                </td>
                                                                <td className='text-end'>
                                                                    {sub_total.toFixed(
                                                                        2
                                                                    )}
                                                                </td>
                                                            </tr>
                                                            <tr className='border-top border-top-dashed fs-15'>
                                                                <th scope='row'>
                                                                    {translate(
                                                                        'Total Amount'
                                                                    )}
                                                                </th>
                                                                <th className='text-end'>
                                                                    {
                                                                        invoice.inv_amount
                                                                    }
                                                                </th>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default InvoiceDetails;
