import { store, persistor } from '../store';
import * as Flags from 'country-flag-icons/react/3x2'
import { hasFlag } from 'country-flag-icons'

export function translate(fieldName, lang_column) {
    let state = store.getState()
    let getLangLabels = state.Language.getLangLabels
    let defaultLanguage = state.Language.defaultLanguage
    let loadingLanguages = state.Language.loadingLanguages
    let loadingLabelLanguages = state.Language.loadingLabelLanguages


    let data = fieldName
    if (defaultLanguage != null && !loadingLanguages && !loadingLabelLanguages) {
        if (lang_column == undefined) {
            lang_column = defaultLanguage['lang_column']
        }

        let translated = getLangLabels.find((v, i) => {
            return v.field_name == fieldName
        })
        data = (translated != undefined) ? translated[lang_column] : fieldName
        // console.log('translation', fieldName, data)
    }
    return data
}

export function RenderFlag({ flag, style }) {
    if (hasFlag(flag) === true) {
        const RenderFlag = Flags[flag]
        return <RenderFlag {...style} />
    }
    return null
}